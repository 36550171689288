import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const MaintenanceSignatureTech = ({ customerDetail }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [inspection, setInspection] = useState({});
  const [loading, setLoading] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;
  const [signatureMode, setSignatureMode] = useState(true);
  const [customerComment, setCustomerComment] = useState('');
  const [customerCommentError, setCustomerCommentError] = useState(false);
  const canvasRef = useRef(null);
  const [isDrawing, setIsDrawing] = useState(false);

  // Initialize the canvas for signature
  useEffect(() => {
    if (signatureMode) {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');
      ctx.clearRect(0, 0, canvas.width, canvas.height);
    }
    fetchInspectionDetails();
  }, [apiUrl, navigate, signatureMode, id]);

  const fetchInspectionDetails = async () => {
    try {
      const response = await axios.get(`${apiUrl}/contracts_crud.php?getInspectionDetails=${id}`, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
      });
      console.log(response.data, 'response from MaintenanceSignatureTech');
      
      if (response.data[0] === 'success') {
        const inspectionData = response.data[1] ?? [];
        setInspection(inspectionData);
      } else {
        toast.error(response.data.error, { position: 'top-center' });
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error('Session expired. Please login again.', { position: 'top-center' });
        navigate('/login');
      } else if (error.response && error.response.status === 403) {
        toast.error("Oops!! you don't have access to this area. Speak to the CFO.", { position: 'top-center' });
      }
    } finally {
      setLoading(false);
    }
  };

  // Drawing functionality for both mouse and touch
  const startDrawing = (e) => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const rect = canvas.getBoundingClientRect();
    let x, y;

    if (e.touches) { // For touch events
      x = e.touches[0].clientX - rect.left;
      y = e.touches[0].clientY - rect.top;
    } else { // For mouse events
      x = e.clientX - rect.left;
      y = e.clientY - rect.top;
    }

    ctx.beginPath();
    ctx.moveTo(x, y);
    setIsDrawing(true);
  };

  const draw = (e) => {
    if (!isDrawing) return;

    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const rect = canvas.getBoundingClientRect();
    let x, y;

    if (e.touches) { // For touch events
      x = e.touches[0].clientX - rect.left;
      y = e.touches[0].clientY - rect.top;
    } else { // For mouse events
      x = e.clientX - rect.left;
      y = e.clientY - rect.top;
    }

    ctx.lineTo(x, y);
    ctx.stroke();
  };

  const stopDrawing = () => {
    setIsDrawing(false);
  };

  const saveSignature = async (status) => {
    const canvas = canvasRef.current;
    // Convert the canvas content to a blob (image format)
    canvas.toBlob(async (blob) => {
      if (!blob) {
        toast.error("Signature is empty. Please provide a signature.", { position: 'top-center' });
        return;
      }

      const formData = new FormData();
      formData.append('status_update', status); // Include the signature status (e.g., COMPLETED or INCOMPLETE)
      formData.append('status', 'inspectionComplete'); //
      formData.append('id', id); // The ID related to this inspection ID
      formData.append('signature', blob, 'signature.png'); // Append the blob as a file
      try {
        const response = await axios.post(`${apiUrl}/maintenance_signature_upload.php`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        });
        console.log(response.data, 'response from MaintenanceSignatureTech');
        if (response.data.success) {
          toast.info('Signature uploaded successfully!', { position: 'top-center' });
          navigate('/t/tinspection');
          // Optionally, handle additional actions upon successful upload
        } else {
          toast.error(response.data.error || "Error uploading signature", { position: 'top-center' });
        }
      } catch (error) {
        toast.error("An error occurred during signature upload.", { position: 'top-center' });
        console.error('Error uploading signature', error);
      }
    }, 'image/png'); // Save as PNG
  };


  const handleClearCanvas = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);
  };

  return (
    <div className="ml-4">
      <div>
        <div className="text-sm font-bold text-green-600">ID: {inspection.id} | {inspection.customer_name}</div>
        <div className="text-xs text-gray-500">
          {inspection.job_site_address} {inspection.job_site_address2} {inspection.city} {inspection.state} {inspection.zip_code}
        </div>
        <div className="text-xs text-red-600">{inspection.system_type}</div>
        <div className="text-xs text-gray-500">
          <span className='font-bold text-black'>Contact:</span> {inspection.contact_name} | {inspection.contact_phone} <br />
          <span className='font-bold text-black bg-yellow-100'>Notification: 
          {inspection.customer_notification_email} | {inspection.customer_print}
          </span>
        </div>
        <div className="text-xs font-bold text-gray-500">{inspection.TECH}</div>
      </div>

      {signatureMode && (
        <div className="w-full mx-auto mt-4">
          <canvas
            id="canvas"
            ref={canvasRef}
            width={400}
            height={200}
            className="w-11/12 mx-auto border border-black"
            onMouseDown={startDrawing}
            onMouseMove={draw}
            onMouseUp={stopDrawing}
            onMouseLeave={stopDrawing}
            onTouchStart={startDrawing}
            onTouchMove={draw}
            onTouchEnd={stopDrawing}
          >
            Canvas is not supported
          </canvas>
          <div className="flex mt-4">
            <button
              onClick={() => saveSignature('Completed')}
              className="btn btn-primary btn-sm bg-blue-500 text-white py-1 px-2 rounded hover:bg-blue-600 ml-4">
              Completed
            </button>
            <button
              onClick={() => saveSignature('Incomplete')}
              className="btn btn-primary btn-sm bg-red-500 text-white py-1 px-2 rounded hover:bg-red-600 ml-4">
              Incomplete
            </button>
            <button
              onClick={handleClearCanvas}
              className="btn btn-default btn-sm bg-gray-300 text-black py-1 px-2 rounded hover:bg-gray-400 ml-4">
              Clear
            </button>
          </div>
        </div>
      )}

      {/* Customer Hours and Equipment in Table */}
      <div className="mt-6">
        {/* <label className="font-semibold">Customer Hours</label>
        <table className="min-w-full bg-white border border-gray-300 mt-2">
          <thead>
            <tr>
              <th className="py-2 px-4 border-b">Total Hours</th>
              <th className="py-2 px-4 border-b">Hour Type</th>
            </tr>
          </thead>

          <tbody>
            {serviceCalls.add_hours?.map((hour, index) => (
              <tr key={index}>
                <td className="py-2 px-4 border-b">{hour.total_hours} hrs</td>
                <td className="py-2 px-4 border-b">{hour.hour_type}</td>
              </tr>
            ))}
            <tr>
              <td className="py-2 px-4 border-b">1 hr</td>
              <td className="py-2 px-4 border-b">Travel</td>
            </tr>
          </tbody>
        </table> */}

        <br />

        {/* {Array.isArray(serviceCalls?.add_parts) && serviceCalls?.add_parts.length > 0 && 
        (
        <>
         <label className="font-semibold mt-4">Equipment</label>
          <table className="min-w-full bg-white border border-gray-300 mt-2">
            <thead>
              <tr>
                <th className="py-2 px-4 border-b">Part</th>
                <th className="py-2 px-4 border-b">Quantity</th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(serviceCalls?.add_parts) && serviceCalls?.add_parts.length > 0 .map((equipment, index) => (
                <tr key={index}>
                  <td className="py-2 px-4 border-b">{equipment.part}</td>
                  <td className="py-2 px-4 border-b">{equipment.quantity}</td>
                </tr>
              ))}
            </tbody>
          </table>       
        </>
        )} */}
      </div>

      {/* Customer Comment Form */}
      {!signatureMode && (
        <div className="mt-4 p-4">
          <form>
            <label className="block mb-2 font-semibold">Customer's Comment</label>
            <textarea
              type="text"
              name="customerComment"
              value={customerComment}
              onChange={(e) => setCustomerComment(e.target.value)}
              minLength={10}
              maxLength={500}
              className="form-control w-full h-28 p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              rows="7"
            />
            {customerCommentError && <p className="text-red-500 text-sm mt-2">Not enough details</p>}
            <br />
            <button
              type="button"
              onClick={() => console.log('Add Comment:', customerComment)}
              className="btn btn-primary btn-sm bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
            >
              Add Comment
            </button>
            <button
              type="button"
              onClick={() => setCustomerComment('')}
              className="btn btn-danger btn-sm bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600 ml-4"
            >
              No Comment
            </button>
          </form>
        </div>
      )}
    </div>
  );
};

export default MaintenanceSignatureTech;
