import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios'; 
import 'react-toastify/dist/ReactToastify.css';
import TopTabs from '../components/TopTabs';
import { NavLink, useNavigate } from 'react-router-dom';
import DateFormat from '../components/DateFormat'; // If you need to format dates
import HeadingCp from '../components/HeadingCp';
import LegendCP from '../components/LegendCP';
import { CalendarDaysIcon } from '@heroicons/react/20/solid';

const PtoReport = () => {
  const [vacationAccruals, setVacationAccruals] = useState([]);
  const [filteredVacationAccruals, setFilteredVacationAccruals] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [year, setYear] = useState('2024');
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();

  // Adjust these tabs if needed or remove them if this page doesn't need them
  const vacation_cal = { name: 'Vacations Cal', href:'office_calendar', current:false };
  const accruals_tab = { name: 'Vacation Accruals', href: '#', current: true };
  const tabs = [vacation_cal, accruals_tab];

  useEffect(() => {
    fetchVacationAccruals();
  }, []);

  const fetchVacationAccruals = async () => {
    try {
      const response = await axios.get(`${apiUrl}/employee_crud.php`, {
        params: {
          getEmployeePTO: true,
        },
        headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
      });
       if(response.data.success) {
        const logs = Array.isArray(response.data.success) ? response.data.success : [];
        setVacationAccruals(logs);
        setFilteredVacationAccruals(logs);
       }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error("Session expired. Please login again.", { position: "top-center" });
        navigate('/login');
      } else if (error.response && error.response.status === 403) {
        toast.error("Oops!! you don't have access to this area. Speak to the CFO.", {
          position: "top-center"
        });
      }
    } finally {
      setLoading(false);
    }   
  };

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);

    if (value === '') {
      setFilteredVacationAccruals(vacationAccruals);
    } else {
      const filtered = vacationAccruals.filter((item) =>
                      (item.first_name && item.first_name.toLowerCase().includes(value.toLowerCase()) ||
                      item.last_name && item.last_name.toLowerCase().includes(value.toLowerCase()) ||
                      item.department && item.department.toLowerCase().includes(value.toLowerCase()) ||
                      item.title && item.title.toLowerCase().includes(value.toLowerCase()))
                    );
      setFilteredVacationAccruals(filtered);
    }
  };

  return (
    <>
    
      <div className="mx-auto max-w-[1250px] px-4 sm:px-6 lg:px-8 py-8">
        <h1 className="text-2xl font-semibold text-gray-500 mb-8">

        <LegendCP color="fill-red-300" label="Over five(5) days" />
        <LegendCP color="fill-green-600" label="Allow over Taken" />
        <br/>
        <br/>

         <HeadingCp label="PTO Report" />
        </h1>
        <div className="flex space-x-3 items-center mb-10">
          <div className="relative rounded-md shadow-sm flex-grow">
            <input
              type="text"
              name="search"
              value={searchTerm}
              onChange={handleSearchChange}
              className="block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
              placeholder="Filter by Name | department | title"
            />
          </div>
        </div>

        {loading ? (
          <div>Loading...</div>
        ) : (
          <table className="min-w-full divide-y divide-gray-300 bg-gray-200 border rounded-lg">
            <thead>
              <tr className='divide-x divide-gray-400'>
                <th scope="col" className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                  Date Hire
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Position
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Names
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  PTO Allow
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  PTO Taken
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Difference
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Sick
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredVacationAccruals.map((item, index) => (
                <tr key={item.id} className={`${index % 2 === 0 ? 'bg-white' : 'bg-gray-100'} 
                                              divide-x`}>
                  <td className="py-4 px-3 text-sm text-gray-700">
                    {item.date_hire ? <DateFormat date={item.date_hire} /> : ''}
                  </td>

                  <td className="py-4 px-3 text-sm text-gray-500">
                    <span className='font-bold text-green-700'>{item.department}</span>
                    <br/>
                    <span className='text-gray-600'>{item.title}</span>
                  </td>

                  <td className="py-4 px-3 text-sm text-gray-500">
                    <NavLink to={`/profile/${item.id}`} className={'text-blue-600'}>
                     <CalendarDaysIcon className='h-5 float-start px-2'/> {item.first_name} {item.last_name}
                    </NavLink>
                  </td>

                  <td className="py-4 px-3 text-sm text-gray-500">
                    {item.vacation_allowance} Day(s)
                  </td>

                  <td className={`py-4 px-3 text-sm text-gray-500 
                                ${item.vacation_allowance > item.vacations_taken ? 'bg-green-300 font-bold' : ''} `}>
                    
                    {item.vacations_taken} Day(s)
                  </td>

                  <td className={`py-4 px-3 text-sm text-gray-500 
                                ${item.vacation_allowance - item.vacations_taken > 5 ? 'text-red-700 font-bold' : ''} `}>
                    {item.vacation_allowance - item.vacations_taken} Day(s)
                  </td>

                  <td className="py-4 px-3 text-sm text-gray-500">
                    {item.all_sickdays.sick_day_allowed} Day(s) allowed
                    <br/> 
                    {item.all_sickdays.sick_day_taken} Day(s) taken
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </>
  );
};

export default PtoReport;
