import React, { useState, useEffect } from 'react';

const MonthSelectorCp = ({ onMonthChange }) => {
  const [monthOptions, setMonthOptions] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState('');

  useEffect(() => {
    const generateMonthOptions = () => {
      const options = [];
      const now = new Date();

      const currentYear = now.getFullYear();
      const currentMonth = now.getMonth(); // 0-based index (0 = Jan, 11 = Dec)

      // Add December of the previous year
      const decemberOfPreviousYear = new Date(currentYear - 1, 11, 1);
      options.push({
        label: decemberOfPreviousYear.toLocaleDateString('en-US', { month: 'short', year: 'numeric' }),
        value: `${decemberOfPreviousYear.getFullYear()}-12`,
      });

      // Add the current month
      const currentMonthDate = new Date(currentYear, currentMonth, 1);
      options.push({
        label: currentMonthDate.toLocaleDateString('en-US', { month: 'short', year: 'numeric' }),
        value: `${currentMonthDate.getFullYear()}-${String(currentMonthDate.getMonth() + 1).padStart(2, '0')}`,
      });

      // Add the next month
      const nextMonthDate = new Date(currentYear, currentMonth + 1, 1);
      options.push({
        label: nextMonthDate.toLocaleDateString('en-US', { month: 'short', year: 'numeric' }),
        value: `${nextMonthDate.getFullYear()}-${String(nextMonthDate.getMonth() + 1).padStart(2, '0')}`,
      });

      return options;
    };

    const options = generateMonthOptions();
    setMonthOptions(options);

    // Set default to the current month only on first render
    if (!selectedMonth) {
      const now = new Date();
      const defaultSelection = `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, '0')}`;
      setSelectedMonth(defaultSelection);
      onMonthChange(defaultSelection);
    }
  }, [onMonthChange, selectedMonth]);

  const handleChange = (event) => {
    const value = event.target.value;
    setSelectedMonth(value);
    onMonthChange(value);
  };

  return (
    <div className="month-selector">
      <label htmlFor="month" className="block text-sm font-medium text-gray-700">
        Select Month
      </label>
      <select
        id="month"
        value={selectedMonth}
        onChange={handleChange}
        className="mt-1 block w-full border border-gray-300 bg-white rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
      >
        {monthOptions.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default MonthSelectorCp;
