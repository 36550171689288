
// time24: 'HH:mm:ss' format
export const formatTimeTo12HourUtil = (time24) => {
  if (!time24) return '';
  const [hourStr, minuteStr, secondStr] = time24.split(':');
  let hour = parseInt(hourStr, 10);
  const minute = minuteStr;
  const ampm = hour >= 12 ? 'PM' : 'AM';
  hour = hour % 12 || 12; // Adjust 0 and 12 to 12
  return `${hour}:${minute} ${ampm}`;
};