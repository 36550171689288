import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useNavigate, NavLink } from 'react-router-dom';
import DateFormat from '../components/DateFormat';
import TopTabs from '../components/TopTabs';
import HeadingCp from '../components/HeadingCp';
import YearMonthSearch from '../components/YearMonthSearch';

const ServiceReport = () => {
  // 1) Default month/year to current
  const now = new Date();
  const defaultYear = now.getFullYear().toString();
  const defaultMonth = (now.getMonth() + 1).toString().padStart(2, '0');

  // 2) Define states
  const [serviceCall, setServiceCall] = useState([]);
  const [filterServiceCall, setFilterServiceCall] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  
  // Our new states for month/year
  const [month, setMonth] = useState(defaultMonth);
  const [year, setYear] = useState(defaultYear);

  // For your API
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();

  // Example tabs
  const vacation_cal = { name: 'Vacations Cal', href: '/office_calendar', current: false };
  const overtime = { name: 'YTD', href: '#', current: true };
  const tabs = [vacation_cal, overtime];

  // 3) Fetch data on initial mount AND whenever month/year changes
  useEffect(() => {
    fetchingServiceCalls();
  }, [month, year]);

  // 4) Data fetch
  const fetchingServiceCalls = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${apiUrl}/service_crud.php?getServiceReport=true&month=${month}&year=${year}`
      );
      const data = response.data[1] || [];
      setServiceCall(data);
      setFilterServiceCall(data);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error("Session expired. Please login again.", {
          position: "top-center"
        });
        navigate('/login');
      } else if (error.response && error.response.status === 403) {
        toast.error("Oops!! you don't have access to this area. Speak to the CFO.", {
          position: "top-center"
        });
      }
    } finally {
      setLoading(false);
    }
  };

  // 5) Searching/filtering logic
  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    if (value === '') {
      // Reset to full list if no search
      setFilterServiceCall(serviceCall);
    } else {
      const filtered = serviceCall.filter(item =>
        (item.TECH && item.TECH.toLowerCase().includes(value.toLowerCase())) ||
        (item.ADDRESS && item.ADDRESS.toLowerCase().includes(value.toLowerCase())) ||
        (item.DATE && item.DATE.toLowerCase().includes(value.toLowerCase()))
      );
      setFilterServiceCall(filtered);
    }
  };

  // Fallback if no service call data is available
  if (!filterServiceCall) {
    return <div>No service call data available</div>;
  }

  return (
    <>
      <div className="mx-auto max-w-[1250px] px-4 sm:px-6 lg:px-8 py-8">
        <HeadingCp label="Service Report (Recurring Calls based on address)" />

        {/* Month/Year Selectors */}
      <YearMonthSearch
        month={month}
        setMonth={setMonth}
        year={year}
        setYear={setYear}
        searchTerm={searchTerm}
        handleSearchChange={handleSearchChange}
      />

        {/* Some action buttons */}
        <div className='p-2 space-x-2 mb-4'>
          <NavLink to="/service_new/new">
            <button
              type="button"
              className="px-4 py-2 border border-transparent text-sm font-medium rounded-md 
                shadow-sm text-white bg-orange-400 hover:bg-orange-700 focus:outline-none 
                focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
            >
              New Service Call
            </button>
          </NavLink>

          <NavLink to="/service_dashboard">
            <button
              type="button"
              className="px-4 py-2 border border-transparent text-sm font-medium rounded-md 
                shadow-sm text-white bg-blue-400 hover:bg-blue-700 focus:outline-none 
                focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Service Dashboard
            </button>
          </NavLink>

          <NavLink to={`/service_cal`} >
            <button
              type="button"
              className="px-4 py-2 border border-transparent text-sm font-medium rounded-md 
                shadow-sm text-white bg-green-400 hover:bg-green-700 focus:outline-none 
                focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
            >
              Calendar View
            </button>
          </NavLink>
        </div>

        {loading ? (
          <div>Loading...</div>
        ) : (
          <table className="min-w-full divide-y divide-gray-300 bg-gray-200 border rounded-lg">
            <thead>
              <tr className='divide-x divide-gray-400'>
                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-2">
                  Log
                </th>
                <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell w-3">
                  Duplicate
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Address / Customer
                </th>
                <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">
                  Date Request
                </th>
                <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">
                  Tech
                </th>
                <th scope="col" className="hidden pl-2 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">
                  Trouble
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Status
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Tech Notes
                </th>
              </tr>
            </thead>
            <tbody>
              {filterServiceCall.map((item, index) => (
                <tr key={item.id} className={`${index % 2 === 0 ? 'bg-white' : 'bg-gray-100'} divide-x`}>
                  <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-700 sm:w-auto sm:max-w-none sm:pl-2">
                    {item.id}
                  </td>
                  <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                    {item.duplicates}
                  </td>
                  <td className="px-3 py-4 text-sm text-gray-500 sm:table-cell">
                    <p className='font-bold text-black'>{item.ADDRESS}</p>
                    <p>{item.CUSTOMER}</p>
                  </td>
                  <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                    {<DateFormat date={item.DATE}/>}
                  </td>
                  <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                    {item.TECH}
                  </td>
                  <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                    {item.TROUBLE}
                  </td>
                  <td className="hidden pl-2 py-4 text-sm text-gray-500 sm:table-cell max-w-[350px]">
                    {item.STATUS}
                  </td>
                  <td className="hidden pl-2 py-4 text-sm text-gray-500 sm:table-cell max-w-[350px]">
                    {item.TECHNOTES}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </>
  );
};

export default ServiceReport;
