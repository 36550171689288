// YearMonthSearch.jsx
import React from 'react';

function YearMonthSearch({
  month,
  setMonth,
  year,
  setYear,
  searchTerm,
  handleSearchChange,
}) {
  // Dynamic years: last year and current year
  const thisYear = new Date().getFullYear();
  const lastYear = thisYear - 1;

  return (
    <div className="flex items-center space-x-4 mb-4">
      {/* Month Selector */}
      <div className="flex flex-col">
        <label htmlFor="month" className="font-semibold text-sm mb-1">
          Month
        </label>
        <select
          id="month"
          className="border rounded px-2 py-1"
          value={month}
          onChange={(e) => setMonth(e.target.value)}
        >
          {Array.from({ length: 12 }, (_, i) => {
            const mm = (i + 1).toString().padStart(2, '0');
            return (
              <option key={mm} value={mm}>
                {mm}
              </option>
            );
          })}
        </select>
      </div>

      {/* Year Selector (last year, current year) */}
      <div className="flex flex-col">
        <label htmlFor="year" className="font-semibold text-sm mb-1">
          Year
        </label>
        <select
          id="year"
          className="border rounded px-2 py-1"
          value={year}
          onChange={(e) => setYear(e.target.value)}
        >
          <option value={lastYear}>{lastYear}</option>
          <option value={thisYear}>{thisYear}</option>
        </select>
      </div>

      {/* Search Field */}
      <div className="relative rounded-md shadow-sm flex-grow">
        <label htmlFor="searchTerm" className="font-semibold text-sm mb-1 block">
          Search
        </label>
        {/* <div className="relative rounded-md shadow-sm flex-grow"> */}
        <input
          id="searchTerm"
          type="text"
          value={searchTerm}
          onChange={handleSearchChange}
          placeholder="Search by name or date out"
          // className="border rounded px-2 py-1"
          className="block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
        />
      </div>
    </div>
  );
}

export default YearMonthSearch;
