import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import CustomTabs from '../components/CustomTabs';
import CalendarViewFDNY from '../components/CalendarViewFDNY';
import HeadingCp from '../components/HeadingCp';
import FDNYNewInspectionModal from '../components/FDNYNewInspectionModal'; // wherever you placed it
import FDNYlistCp from '../components/FDNYlistCp';

const FdnyInspection = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [activeLink, setActiveLink] = useState('Calendar View');
  const [showModal, setShowModal] = useState(false);
  const [editData, setEditData] = useState(null);

  useEffect(() => {
    fetchSomething();
  }, []);

  const fetchSomething = async () => {
    try {
      setLoading(true);
      // e.g., const res = await axios.get(...);
      // handle results
    } catch (error) {
      if (error.response?.status === 401) {
        toast.error('Session expired', { position: 'top-center' });
        navigate('/login');
      } else {
        toast.error('Error fetching data', { position: 'top-center' });
      }
    } finally {
      setLoading(false);
    }
  };

  const handleCalendarView = () => setActiveLink('Calendar View');
  const handleListView = () => setActiveLink('FDNY List View');

  // Opens the modal for a new inspection
  const openNewInspection = () => {
    setEditData(null);
    setShowModal(true);
  };

  const openEditInspection = (row) => {
    setEditData(row);
    setShowModal(true);
  };

  const handleSubmitInspection = async (formData) => {
     if(formData.data.address === null || 
        formData.data.tech_inspection === null || 
        formData.data.start_time === null || 
        formData.data.jobnum === null || 
        formData.data.date === null) { 
          toast.error('Please fill all the required fields', { position: 'top-center' });
          return;
        }
    
    try {
      // For "edit" if formData.id is not null; else "new"
      const response = await axios.post(`${apiUrl}/fdny_crud.php`, formData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (response.data.success) {
        toast.success('Inspection saved', { position: 'top-center' });
        setShowModal(false);
      } else {
        toast.error('Error saving inspection', { position: 'top-center' });
      }
    } catch (error) {
      console.error(error);
      toast.error('Error saving inspection', { position: 'top-center' });
    }
  };

  const tabs = [
    { label: 'Calendar View', onClick: handleCalendarView },
    { label: 'FDNY List View', onClick: handleListView },
    // { label: '+ New Inspection', onClick: handleSubmitInspection },
  ];

  return (
    <>
      <div className="mx-auto max-w-6xl sm:px-6 lg:px-8 py-10">
        <HeadingCp label="FDNY Inspection" />

        <div className="mt-4">
          <button onClick={openNewInspection}
            className="bg-blue-500 hover:bg-blue-600 text-white px-2 py-1 rounded text-sm">
              New Inspection
          </button>
        </div>       

        <CustomTabs tabs={tabs} />
        <br />
        {activeLink === 'Calendar View' && <CalendarViewFDNY />}
        {activeLink === 'FDNY List View' && <FDNYlistCp />}
      </div>

      {/* The Modal */}
      <FDNYNewInspectionModal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        onSubmit={handleSubmitInspection}
        initialData={editData || {}} 
      />
    </>
  );
};

export default FdnyInspection;
