// pages/InspectionDetails.jsx
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

import InspectionType from './InspectionType';
import DevicesTested from './DevicesTested';
import TechNotes from './TechNotes';
import AdditionalTech from './AdditionalTech';
import LogBooks from './LogBooks';
import CustomerEmail from './CustomerEmail';
import HeadingCp from '../components/HeadingCp';
import Accordion from '../components/Accordion';
import { act } from 'react';

function InspectionDetails() {
  const { id: urlID } = useParams();
  const navigate = useNavigate();

  const [inspection, setInspection] = useState({});
  const [activeInspection, setActiveInspection] = useState(true);
  const [type, setType] = useState('');
  const [deviceList, setDeviceList] = useState({});
  const [inspectionTech, setInspectionTech] = useState({ techNotes: '' });
  const [selectedTech, setSelectedTech] = useState([]);
  const [techList, setTechList] = useState([]);
  const [logbookstatus, setLogbookStatus] = useState('');
  const [inspection_report, setInspectionReport] = useState({});
  const [activateSignature, setActivateSignature] = useState(false);
  const [panelList, setPanelList] = useState([]);
  const [ready, setReady] = useState(false);
  const [showDeviceBox, setShowDeviceBox] = useState(false);
  const [showLogBookBox, setShowLogBookBox] = useState(false);
  const [showCustomerEmailBox, setShowCustomerEmailBox] = useState(false);
  const [openIndex, setOpenIndex] = useState(null);
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    // get panel list
    fetchPanelList(urlID);

    // get inspection data
    fetchInspectionData(urlID);

    // get device list
    fetchDeviceList(urlID);

    // get techs
    fetchTechList(urlID);

  }, [urlID, ready]);

  const fetchPanelList = async() => {
    try {
      const response = await axios.get(`${apiUrl}/contracts_crud.php?getPanel=${urlID}`, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } });
      if (response.data[0] === 'success') {
        setPanelList(response.data[1]);
      } else {
        console.error('Error getting panel list');
      }
    } catch (error) {
      console.error('Error fetching panel list:', error);
    }
  }

  const fetchInspectionData = async() => {
    try {
      const response = await axios.get(`${apiUrl}/contracts_crud.php?getInspectionDetails=${urlID}`, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } });
        console.log(response.data, 'inspection data');
        
      if (response.data[0] === 'success') {
        const data = response.data;
        setInspection(data[1]);
        setActiveInspection(true);
        setInspectionTech({ techNotes: data[1].tech_notes });
        setLogbookStatus(data[1].status);
          
        // If inspectionFeed from data[2] has customer email:
          const inspectionFeed = data[1];
          if (inspectionFeed.customer_notification_email) {
            setActivateSignature(true);
            setInspectionReport({
              customerPrint: inspectionFeed.customer_print,
              customerEmail: inspectionFeed.customer_notification_email
            });
          }

          setType(inspectionFeed.type);
          // Show/hide sections based on conditions:
          if (inspectionFeed.type) {
            setShowDeviceBox(true);
            if (data[3].status) {
              setShowCustomerEmailBox(true);
            } else {
              setShowCustomerEmailBox(false);
            }
          } else {
            setShowDeviceBox(false);
            setShowCustomerEmailBox(false);
          }          

      } else {
        console.error('Error getting inspection data');
      }
    } catch (error) {
      console.error('Error fetching inspection data:', error);
    }
  }

  const fetchDeviceList = async() => {
    try {
      const response = await axios.get(`${apiUrl}/contracts_crud.php?getDeviceList=${urlID}`, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } });
        const data = response.data;
        console.log(data, 'devicesList from fetch');
        
        if (data[0] === 'success') {
          setReady(data[2]);
          setDeviceList(data[1]);

          const logbook = data[4];
          const checkListMainMissing = data[6];

          if (ready) {
            setShowLogBookBox(true);
            if(logbook.status) {
              setLogbookStatus(logbook.status);
              setShowCustomerEmailBox(true);
            }
          } else {
            setShowLogBookBox(false);
            setShowCustomerEmailBox(false);
          }
        } else {
          console.error('Error getting device list');
        }
    } catch (error) {
      console.error('Error fetching device list:', error);
    }
  }

  const fetchTechList = async() => {
    try {
      const response = await axios.get(`${apiUrl}/contracts_crud.php?getAdditionalTech=${urlID}`, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } });
        console.log(response.data, 'techList from fetch');

      if (response.data[0] === 'success') {        
        setTechList(response.data[1]);
        setSelectedTech(response.data[2]);
      } else {
        console.error('Error getting tech list');
      }
    } catch (error) {
      console.error('Error fetching tech list:', error);
    }
  }

  const newInspection = (type) => {
    if (type === 'new') {
      navigate('/mdep_m_inspection/new');
    } else {
      navigate('/mdep_m_inspection/list');
    }
  };

  const signature = () => {
    navigate(`/t/tinspsignature/${urlID}`);
  };

  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="p-5">
      <HeadingCp label={"Maintenance Inspection Details"} />
      {activeInspection && (
        <div>
          <div className="flex flex-row p-2 bg-gray-100 border-1 border-black rounded-md mb-2 p-1">
            <div className="w-full">
              <span className="font-bold text-green-700">{inspection.customer_name}</span><br/>
              <p className='text-gray-600 text-sm'>{inspection.job_site_address}, {inspection.job_site_address2}</p> 
              <span className='text-gray-600 text-sm'>{inspection.city} {inspection.state} {inspection.zip_code}</span>
              <p className="text-sm">{inspection.system_type} :Due {inspection.scheduled_due_date} - {inspection.schedule_date_to}</p>
              <p className="text-sm">{inspection.contact_name} : {inspection.contact_phone}</p>
            </div>
          </div>
          <div>

            {/* adding tech */}
            <Accordion
              title="All Devices Tested and Operational (Required)"
              content={
              <>
                  {/* Devices Tested and Operational */}
                  {/* {type && ( */}
                    <DevicesTested
                      deviceList={deviceList}
                      setDeviceList={setDeviceList}
                      urlID={urlID}
                      ready={ready}
                      setReady={setReady}
                      showLogBookBox={showLogBookBox}
                      setShowLogBookBox={setShowLogBookBox}
                      setShowCustomerEmailBox={setShowCustomerEmailBox}
                    />
                  {/* )} */}
              </>
              }
              isOpen={openIndex === 0}
              toggle={() => toggleAccordion(0)}
            />

            {/* Tech Notes */}
            <Accordion
              title="Tech Notes"
              content={
              <>
                {/* {type && ( */}
                  <TechNotes 
                    inspectionTech={inspectionTech} 
                    setInspectionTech={setInspectionTech}
                    urlID={urlID}
                  />
                {/* )} */}
              </>
              }
              isOpen={openIndex === 1}
              toggle={() => toggleAccordion(1)}
            />

            <Accordion
              title="Additional Tech"
              content={
              <>
                {/* Additional Tech */}
                <AdditionalTech 
                    selectedTech={selectedTech} 
                    setSelectedTech={setSelectedTech}
                    techList={techList}
                    urlID={urlID}
                  />
                {/* )} */}
              </>
              }
              isOpen={openIndex === 3}
              toggle={() => toggleAccordion(3)}
            />

            <Accordion
              title="Log Books (Required)"
              content={
              <>
                {/* Log Books */}
                { !showLogBookBox ? <span className='text-red-700'>You need to verify all devices first</span> : (
                  <LogBooks 
                    logbookstatus={logbookstatus}
                    setLogbookStatus={setLogbookStatus}
                    urlID={urlID}
                    setShowCustomerEmailBox={setShowCustomerEmailBox}
                  />
                )}
              </>
              }
              isOpen={openIndex === 4}
              toggle={() => toggleAccordion(4)}
            />

            <Accordion
              title="Ticket notification (Required)"
              content={
              <>
                {/* Customer Email */}
                { showCustomerEmailBox && (
                  <CustomerEmail
                    inspection_report={inspection_report}
                    setInspectionReport={setInspectionReport}
                    activateSignature={activateSignature}
                    setActivateSignature={setActivateSignature}
                    urlID={urlID}
                    signature={signature}
                  />
                )}
              </>
              }
              isOpen={openIndex === 5}
              toggle={() => toggleAccordion(5)}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default InspectionDetails;
