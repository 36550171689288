import React, { useState, useEffect } from 'react';
import axios from 'axios';
import LoadingSpinner from '../components/LoadingSpinner';
import DateFormat from '../components/DateFormat';
import { useUserData } from '../context/UserData';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import HeadingCp from '../components/HeadingCp';
import MonthTabs from '../components/MonthTabs';
import Badge from '../components/Badge';
import SubMenu from '../components/SubMenu';

const InspectionActiveContract = () => {
  const [contracts, setContracts] = useState([]);
  const [loading, setLoading] = useState(true);
  // const userData = useUserData();
  const navigate = useNavigate();
  const [currentMonth, setCurrentMonth] = useState(() => {
    const date = new Date();
    return String(date.getMonth() + 1).padStart(2, '0');
  });
  const apiUrl = process.env.REACT_APP_API_URL;
  const api = `${apiUrl}/contracts_crud.php`;
  const months = [
      { name: 'All', value: 'all' },
      { name: 'January', value: '01' },
      { name: 'February', value: '02' },
      { name: 'March', value: '03' },
      { name: 'April', value: '04' },
      { name: 'May', value: '05' },
      { name: 'June', value: '06' },
      { name: 'July', value: '07' },
      { name: 'August', value: '08' },
      { name: 'September', value: '09' },
      { name: 'October', value: '10' },
      { name: 'November', value: '11' },
      { name: 'December', value: '12' },
    ];

  useEffect(() => {
    fetchInspections();
  }, []);

  const fetchInspections = async (selectedMonth = currentMonth) => {
  setLoading(true);
  try {
    const response = await axios.get(api, {
      params: {
        getContractDeactivated: true,
        month: selectedMonth,
      },
    });

    if (response.data) {
      setContracts(response.data || []);
    } else {
      console.error('Error fetching contracts');
    }
  } catch (error) {
      // Handle errors
      if (error.response && error.response.status === 401) {
        toast.error("Session expired. Please login again.", {
          position: "top-center",
        });
        navigate('/login');
      } else if (error.response && error.response.status === 403) {
        toast.error("Access denied.", {
          position: "top-center",
        });
      } else {
        toast.error("Error fetching contracts", {
          position: 'top-center',
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const handleMonthChange = (monthValue) => {
    setCurrentMonth(monthValue);
    fetchInspections(monthValue);
  };

  const getLinksForContract = (contract) => [
    { name: 'Edit Contract', click: () => navigate(`/maintenance_contract/edit/${contract?.id}`) },
    // { name: 'Record Inspection', click: () => handleRecordInspection(contract.id)},
    { name: contract.status === 1 ? 'Deactivate Contract' : 'Activate Contract', click: () => actvieContract(contract.id, contract.status) },
  ];

  const actvieContract = async (id, currentStatus) => {
    setLoading(true);
    const newStatusActive = currentStatus === 1 ? 0 : 1;
    const bodyData = {
      status: 'active',
      data: { contract_id: id, status: newStatusActive }
    };
    try {
      const response = await axios.post(api, bodyData, {
        headers: { 'Content-Type': 'application/json' }
      });
      if (response.data.success) {
        setContracts(prev => prev.map(contract => {
          if (contract.id === id) {
            return { ...contract, status: newStatusActive };
          }
          return contract;
        }));
        toast.success('Contract status updated!', { position: 'top-center' });
      } else {
        toast.error('Error updating contract', { position: 'top-center' });
      }
    } catch (error) {
        if (error.response && error.response.status === 401) {
        toast.error("Session expired. Please login again.", {
        position: "top-center"
        });
        navigate('/login');
        } else if (error.response && error.response.status === 403) {
        toast.error("Oops!! you don't have access to this area. Speak to the CFO.", {
        position: "top-center"
        });
        }
    } finally {
      setLoading(false);
    }
  };  

  return (
    <div className="mx-auto max-w-[1250px] px-4 sm:px-6 lg:px-8 py-8">
      <HeadingCp label="Deactivated Contracts List" />
      {/* Month Tabs */}
      <br/>
    <div className="flex space-x-3 items-center mb-10">
     <button
          onClick={() => navigate(`/maintenance`)}
          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium 
                    rounded-md shadow-sm text-white bg-green-600 hover:bg-green-400 focus:outline-none 
                    focus:ring-2 focus:ring-offset-2 focus:ring-black-500">
          Contracts
      </button>   
      </div>

      {loading ? (
        <LoadingSpinner>Loading...</LoadingSpinner>
      ) : (
        <table className="min-w-full divide-y divide-gray-300 bg-gray-200 border rounded-lg">
          <thead>
            <tr className="divide-x divide-gray-400">
              <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                ID/Menu
              </th>
              <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                Job Site
              </th>
              <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                Customer Name
              </th>
              <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                Schedule Contact
              </th>
              <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                Scheduled Date
              </th>
              <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                Actual Inspection Date
              </th>
              <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                Status
              </th>
              {/* Add other headers as needed */}
            </tr>
          </thead>
          <tbody>
            {contracts.length === 0 && (
              <tr>
                <td colSpan="8" className="text-center py-4 text-gray-500">
                  No contracts found.
                </td>
              </tr>
            )}
            {contracts.map((contract, index) => (
              <tr key={contract.id} className={`${index % 2 === 0 ? 'bg-white' : 'bg-gray-100'} divide-x`}>
                <td className="px-3 py-4 text-sm text-gray-500">
                  <SubMenu label={contract.id} links={getLinksForContract(contract)} idItem={contract.id} />
                  <span className="text-gray-400 text-xs">{contract.contract_type}</span>
                </td>
                <td className="px-3 py-4 text-sm text-gray-500">
                  <span className='font-bold text-black'>
                      {contract.job_site_address}
                      {contract.job_site_city && (' '+ contract.job_site_city + ', ')} 
                      {contract.job_site_state && (' '+ contract.job_site_state + ' ')} 
                      {contract.job_site_zip && (' '+ contract.job_site_zip)} 
                  </span>
                </td>
                <td className="px-3 py-4 text-sm text-gray-500">
                  <p className="text-sm text-gray-400">{contract.customer_name}</p>
                </td>

                <td className="px-3 py-4 text-sm text-gray-500">
                      {contract.contact_name}
                      <p>
                        {contract.contact_phone && (' '+ contract.contact_phone + ', ')} 
                      </p>
                      <p>
                        {contract.contact_email && (' '+ contract.contact_email + ' ')}
                      </p>
                </td>
                <td className="px-3 py-4 text-sm text-gray-500">
                  <DateFormat date={contract.scheduled_due_date} />
                </td>
                <td className="px-3 py-4 text-sm text-gray-500">
                  <DateFormat date={contract.actual_contract_date} />
                </td>
                <td className="px-3 py-4 text-sm text-gray-500">
                  <Badge color={'yellow'} feed={'Deactivated Contract'} />
                </td>
                {/* <td className="px-3 py-4 text-sm text-gray-500">
                  <button onClick={() => navigate(`/contract/${contract.id}`)}>
                    {contract.id}
                  </button>
                </td> */}

              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default InspectionActiveContract;
// copy the code from the contracts page and modify it to display the deactivated contracts.
