// OvertimeDashboard.jsx

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import HeadingCp from '../components/HeadingCp';
import Accordion from '../components/Accordion';
import LoadingSpinner from '../components/LoadingSpinner';
import LegendCP from '../components/LegendCP';
import SubMenu from '../components/SubMenu';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';

export default function OvertimeDashboard() {
  const [list, setList] = useState({}); 
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [openIndex, setOpenIndex] = useState(null);
  const apiUrl = process.env.REACT_APP_API_URL; 

  useEffect(() => {
    fetchData('current');
  }, []);

  const fetchData = async (weekType) => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${apiUrl}/employee_crud.php?getOvertimeAvailableRecords=${weekType}`,
        {
          params: { getOvertimeAvailableRecords: weekType },
          headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
        }
      );
      if (response.data[0] === 'success') {
        setList(response.data[1] || {});
      }
    } catch (err) {
      console.error('Error fetching overtime data:', err);
    } finally {
      setLoading(false);
    }
  };

  const toggleAccordion = (index) => {
    setOpenIndex((prev) => (prev === index ? null : index));
  };

  const handleCrtWk = () => {
    fetchData('current');
  };

  const handleNexWk = () => {
    fetchData('next');
  };

  const handleSchedule = async(dayKey, itemId, index, result) => {
    const bodyData = {
      id: itemId,
      status: 'scheduled_overtime',
      result: result
    }
    try {
      setLoading(true);
      const response = await axios.post(`${apiUrl}/employee_crud.php`, bodyData, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if(response.data.success) {
        toast.success('Overtime ' + result + ' successfully.', {
          position: "top-center"
        });
        setList((prev) => {
          const newList = { ...prev };
          const items = [...(newList[dayKey] || [])];

          const item = { ...items[index] };
          item.status = item.status === 'scheduled' ? '' : 'scheduled';

          items[index] = item;
          newList[dayKey] = items;

          return newList;
        });
      } else {
        toast.error("Error scheduling overtime.", {
          position: "top-center"
        });
      }
      
    } catch (error) {
      console.error('Error scheduling overtime:', error);
      if (error.response && error.response.status === 401) {
        toast.error("Session expired. Please login again.", {
        position: "top-center"
        });
      navigate('/login');
      } else if (error.response && error.response.status === 403) {
        toast.error("Oops!! you don't have access to this area. Speak to the CFO.", {
        position: "top-center"
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const getMenuItems = (key, item, index) => [
    { name: item.status === 'scheduled' ? 'Unschedule' : 'Scheduled', click: () => 
            handleSchedule(key, item.id, index, item.status === 'scheduled' ? 'pending' : 'scheduled') 
    },
  ];  

  return (
    <div className="mx-auto max-w-[1250px] px-4 sm:px-6 lg:px-8 py-8">
      {/* Title */}
      <HeadingCp label="Overtime Dashboard" />

      {/* Legend */}
      <LegendCP color={'fill-green-400'} label={'Scheduled'} />
      <br/>
      <br/>

      {/* Buttons to switch weeks */}
      <div className="space-x-4 mb-4">
        <button
          className="bg-yellow-400 hover:bg-yellow-500 text-white text-md font-medium px-3 py-1 rounded"
          onClick={handleCrtWk}
        >
          current wk
        </button>
        <button
          className="bg-green-600 hover:bg-green-700 text-white text-md font-medium px-3 py-1 rounded"
          onClick={handleNexWk}
        >
          next week wk
        </button>
      </div>

      {loading ? (
        <LoadingSpinner  children={'Loading....'}/>
      ) : (
        // Loop over each key in the list to create an accordion
        Object.entries(list).map(([key, items], idx) => {
          const tableContent = (
            <table className="table-auto w-full border border-gray-300">
              <thead>
                <tr className="bg-gray-100 border-b border-gray-300">
                  <th className="py-2 px-4 text-left text-gray-700 w-1/4">Schedule</th>
                  <th className="py-2 px-4 text-left text-gray-700">Details</th>
                </tr>
              </thead>
              <tbody>
                {Array.isArray(items) &&
                  items.map((item, index) => (
                    <tr key={item.id}
                      className={`border-b border-gray-300 ${item.status === 'scheduled' ? 'bg-green-100' : 
                      `${index % 2 === 0 ? 'bg-white' : 'bg-gray-100'} divide-x`}
                      }`}>
                      {/* Column 1: Schedule button */}
                      <td className="py-2 px-4 align-top">
                        <div className="">
                           <SubMenu label={item.id} 
                                    links={getMenuItems(key, item, index)} 
                                    idItem={item.id} />
          
                        </div>
                      </td>

                      {/* Column 2: Subtable with item details */}
                      <td className="py-2 px-4 align-top">
                        <table className="table-auto w-full border border-gray-300">
                          <tbody>
                            {/* Row 1: Username & Tech Need */}
                            <tr className="border-b border-gray-300">
                              <td className="py-1 px-2 text-gray-500 text-sm">Username</td>
                              <td className="py-1 px-2">{item.username}</td>
                              <td className="py-1 px-2 text-gray-500 text-sm">Tech Need</td>
                              <td className="py-1 px-2">{item.tech_need}</td>
                            </tr>

                            {/* Row 2: Job Number & Address */}
                            <tr className="border-b border-gray-300">
                              <td className="py-1 px-2 text-gray-500 text-sm">Job Number</td>
                              <td className="py-1 px-2">{item.job_num || '—'}</td>
                              <td className="py-1 px-2 text-gray-500 text-sm">Job Address</td>
                              <td className="py-1 px-2">{item.job_address || '—'}</td>
                            </tr>

                            {/* Row 3: Date & Date Requested */}
                            <tr>
                              <td className="py-1 px-2 text-gray-500 text-sm">Date</td>
                              <td className="py-1 px-2">{item.date}</td>
                              <td className="py-1 px-2 text-gray-500 text-sm">Date Requested</td>
                              <td className="py-1 px-2">{item.date_requested}</td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          );

          return (
            <div key={key} className={`mb-6 ${Array.isArray(items) ? 'text-green-700' : ''}`}>
              {/* Use the Accordion component */}
              <Accordion
                title={key}
                content={tableContent}
                isOpen={openIndex === idx}
                toggle={() => toggleAccordion(idx)}
              />
            </div>
          );
        })
      )}
    </div>
  );
}
