import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios'; // Make sure Axios is imported
import 'react-toastify/dist/ReactToastify.css';
import DateFormat from '../components/DateFormat';
import TopTabs from '../components/TopTabs';
import { useNavigate } from 'react-router-dom';

const VacationYtD = () => {
  const [vacationYTD, setVacationYTD] = useState([]);
  const [filterVacationYTD, setFilterVacationYTD] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const currentYear = new Date().getFullYear();
  const [year, setYear] = useState(String(currentYear));
  const apiUrl = process.env.REACT_APP_API_URL;
  const api = `${apiUrl}/time_attendance_crud.php`;
  const navigate = useNavigate()


  const vacation_cal = { name: 'Vacations Cal', href:'office_calendar', current:false };
  const overtime = { name: 'YTD', href: '#', current: true };

  const tabs = [vacation_cal, overtime];


  useEffect(() => {
    fetchAccountingVendor();
  }, []);

  const fetchAccountingVendor = async () => {

     try {
            const response = await axios.get(api, {
                params: {
                  ytd: 'vacations',
                  year: year
                }
            });
          const data = response.data;
        if (response.data && Array.isArray(response.data)) {
          setVacationYTD(response.data);
          setFilterVacationYTD(response.data);
        }
        else if(data.error)
        {
          toast.error(data.error, {
            position: 'top-center'
          });
        }
        else 
        {
          toast.error("error getting array", {
            position: 'top-center'
          });
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
        toast.error("Session expired. Please login again.", {
        position: "top-center"
        });
        navigate('/login');
        } else if (error.response && error.response.status === 403) {
        toast.error("Oops!! you don't have access to this area. Speak to the CFO.", {
        position: "top-center"
        });
        }
      } finally {
        setLoading(false);
      }   
  };

  const handleSearchChange = (event) => {
     const value = event.target.value;
    setSearchTerm(value);
    if (value === '') {
        setVacationYTD(vacationYTD);
    } else {
        const filtered = vacationYTD.filter(vacationYTD =>
            (vacationYTD.employee_name && vacationYTD.employee_name.toLowerCase().includes(value.toLowerCase())) ||
            (vacationYTD.date_out && vacationYTD.date_out.toLowerCase().includes(value.toLowerCase()))
        );
        setFilterVacationYTD(filtered);
    }
  };

  return (
    <>
      <div className="mx-auto max-w-[1250px] px-4 sm:px-6 lg:px-8 py-8">
        <br/>
        <br/>
        <TopTabs tabs={tabs}  />
        <br/>
        <br/>
        <h1 className="text-2xl font-semibold text-gray-500 mb-8">Vacations YTD</h1>
        <div className="flex space-x-3 items-center mb-10">
              <div className="relative rounded-md shadow-sm flex-grow">
               <input
                  type="text"
                  name="search"
                  value={searchTerm}
                  onChange={handleSearchChange}
                  className="block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
                  placeholder="Filter by Name | Month (01) | Day (01)"
                />
              </div>
        </div>

        {loading ? (
          <div>Loading...</div>
        ) : (
          <table className="min-w-full divide-y divide-gray-300 bg-gray-200 border rounded-lg">
            <thead>
              <tr className='divide-x divide-gray-400'>
                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-2">
                  ID
                </th>
                <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell w-3">
                  E-ID
                </th>
                <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">
                  Employee Name
                </th>
                <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">
                  Day Type
                </th>
                <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">
                  Date Out
                </th>
                <th scope="col" className="hidden pl-2 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">
                  Date Return
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Status
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Notes
                </th>
              </tr>
            </thead>
            <tbody>
               {filterVacationYTD.map((vacationYTD, index) => (
                <tr key={vacationYTD.id} className={`${index % 2 === 0 ? 'bg-white' : 'bg-gray-100'} divide-x`}>
                  <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-700 sm:w-auto sm:max-w-none sm:pl-2">
                      {vacationYTD.id}
                      <br />
                     <dl className="font-normal lg:hidden">
                      <dd className="mt-1 truncate text-gray-700">{vacationYTD.employee_name}</dd>
                      <dd className="mt-1 truncate text-green-500 sm:hidden">{<DateFormat date={vacationYTD.date_out}/>}</dd>
                      <dd className="mt-1 truncate text-blue-500 sm:hidden">{<DateFormat date={vacationYTD.date_end}/>}</dd>
                    </dl>
                  </td>
                  <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">{vacationYTD.employee_id}</td>
                  <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">{vacationYTD.employee_name}</td>
                  <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">{vacationYTD.date_request}</td>
                  <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">{<DateFormat date={vacationYTD.date_out}/>}</td>
                  <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">{<DateFormat date={vacationYTD.date_end}/>}</td>
                  <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">{vacationYTD.status}</td>
                  <td className="hidden pl-2 py-4 text-sm text-gray-500 sm:table-cell max-w-[350px]">{vacationYTD.notes}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </>
  );
}

export default VacationYtD;