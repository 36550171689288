import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { UtilConvertDateTodayDate } from '../utils/UtilConvertDateTodayDate';
import HeadingCp from '../components/HeadingCp';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import LegendCP from '../components/LegendCP';
import DateFormat from '../components/DateFormat';
import { TrashIcon } from '@heroicons/react/20/solid';

const OnCallList = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const [onCallList, setOnCallList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [newTech, setNewTech] = useState({
    employee_name: '',
    start_date: '',
    end_date: '',
    phone: '',
  });

  useEffect(() => {
    const fetchServiceOnCall = async () => {
      try {
        const response = await axios.get(`${apiUrl}/service_crud.php?get_on_call_list=1`, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          },
        });

        if (response.data[0] === 'success') {
          setOnCallList(response.data[1]);
        } else {
          toast.error('No data found! (service call)', {
            position: 'top-center',
          });
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          toast.error('Session expired. Please login again.', {
            position: 'top-center',
          });
          navigate('/login');
        }
      }
    };
    fetchServiceOnCall();
    fetchEmployees(apiUrl);
  }, [apiUrl, navigate]);

  const fetchEmployees = async () => {
      try {
        const response = await axios.get(`${apiUrl}/service_crud.php?employee_list=true`, {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
        });
        setEmployees(response.data);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          navigate('/login');
        } else if (error.response && error.response.status === 403) {
          toast.error("Oops!! you don't have access to this area. Speak to the CFO.", { position: "top-center" });
        }
      } 
  }

  const isWeekTaken = (startDate, endDate) => {
    const sanitizeDate = (dateStr) => {
      const date = new Date(dateStr);
      date.setHours(0, 0, 0, 0); // Normalize to midnight
      return date;
    };

    const newStart = sanitizeDate(startDate);
    const newEnd = sanitizeDate(endDate);

    // Check for conflicts
    const conflictingTech = onCallList.find((tech) => {
      const techStart = sanitizeDate(tech.start_date);
      const techEnd = sanitizeDate(tech.end_date);

      // Conflict if:
      // 1. New start date is within an existing range (excluding end date)
      // 2. New end date overlaps with an existing range
      return (
        (newStart > techStart && newStart < techEnd) || // Rule: Start within existing range
        (newStart === techStart) || // Rule: Start matches existing start (not allowed)
        (newEnd > techStart && newStart < techStart) // End overlaps but starts valid
      );
    });

    return conflictingTech ? conflictingTech.employee_name : null;
  };

  const handleAddTech = async () => {
    const { employee_id, employee_name, start_date, end_date, phone } = newTech;
    const overlappingTech = isWeekTaken(start_date, end_date);

    if (overlappingTech) {
      toast.error(`This week is already taken by ${overlappingTech}`, {
        position: 'top-center',
      });
      return;
    }

    if(start_date > end_date) {
      toast.error('End date must be after start date!', {
        position: 'top-center',
      });
      return;
    }
      try {
        const response = await axios.post(
         `${apiUrl}/service_crud.php`,{
          status: 'add_on_call_tech', employee_id, employee_name, start_date, end_date,phone,
          }, { headers: { 'Content-Type': 'application/json' }, }
        );

        if (response.data[0] === 'success') {
            toast.success('Tech added successfully!', {  position: 'top-center',});
            const addedEmployee = employees.find((emp) => emp.id === parseInt(employee_id));
            const employee_name = addedEmployee ? `${addedEmployee.first_name} ${addedEmployee.last_name}` : 'Unknown';

            // Update the onCallList with the new tech
            setOnCallList((prev) => [...prev, {...response.data[1], employee_name, }, ]);
            setIsModalOpen(false);
        } else {
          toast.error(response.data.error || 'Failed to add tech!', {
            position: 'top-center',
          });
        }
      } catch (err) {
        console.error('Error adding tech:', err.response || err.message);
        toast.error('Error adding tech!', { position: 'top-center' });
      }
  };

  const getCurrentWeekRange = () => {
    const now = new Date();
    const monday = new Date(now.setDate(now.getDate() - now.getDay() + 1)); // Get Monday
    const friday = new Date(monday);
    friday.setDate(monday.getDate() + 4); // Get Friday

    // Normalize times to midnight
    monday.setHours(0, 0, 0, 0);
    friday.setHours(23, 59, 59, 999);

    return { start: monday, end: friday };
  };

  const isInCurrentWeek = (startDate, endDate) => {
    const sanitizeDate = (dateStr) => {
      const date = new Date(dateStr);
      date.setHours(0, 0, 0, 0); // Normalize to midnight
      return date;
    };

    const startDt = sanitizeDate(startDate);
    const endDt = sanitizeDate(endDate);

    const { start, end } = getCurrentWeekRange();

    // Check if the date range overlaps with the current week
    return startDt <= end && endDt >= start;
  };

  const handleDelete = async (oncall_id) => {
    try {
      const response = await axios.post(
        `${apiUrl}/service_crud.php`,
        {
          status: 'delete_on_call_tech',
          data: { oncall_id },
        },
        {
          headers: { 'Content-Type': 'application/json' }, // Move headers here
        }
      );

      if (response.data[0] === "success") {
        toast.success("Date deleted successfully!", {
          position: "top-center",
        });

        // Update the onCallList state
        setOnCallList((prev) => prev.filter((item) => item.id !== oncall_id));
      } else {
        toast.error(response.data.message || "Failed to delete date.", {
          position: "top-center",
        });
      }
    } catch (error) {
      console.error("Error during deletion:", error);
      toast.error("An error occurred. Please try again.", {
        position: "top-center",
      });
    }
  };

  return (
    <>
      <div className="mx-auto max-w-6xl mt-20">
        <HeadingCp label="On Service Call" />
        <br />
        <LegendCP color="fill-green-300" label="Active On Call" />
        <br/>
        <br/>
        <button
          className="mb-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
          onClick={() => setIsModalOpen(true)}>
          Add Tech
        </button>
        <div>
          <div className="inline-block min-w-full py-2 align-middle">
            <table className="min-w-full max-w-4xl mx-auto divide-y divide-gray-300 border">
              <thead>
                <tr>
                  <th className="py-3.5 bg-gray-200 pl-4 pr-3 text-center border border-gray-300 text-sm font-semibold text-gray-900">
                    ID
                  </th>
                  <th className="px-3 py-3.5 bg-gray-200 text-left border border-gray-300 text-sm font-semibold text-gray-900">
                   ID | Employee Name
                  </th>
                  <th className="px-3 py-3.5 bg-gray-200 text-left border border-gray-300 text-sm font-semibold text-gray-900">
                    Start Date
                  </th>
                  <th className="px-3 py-3.5 bg-gray-200 text-left border border-gray-300 text-sm font-semibold text-gray-900">
                    End Date
                  </th>
                  <th className="px-3 py-3.5 bg-gray-200 text-left border border-gray-300 text-sm font-semibold text-gray-900">
                    Phone
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {onCallList?.map((onCall) => (
                  <tr
                    key={onCall.id}
                    className={`divide-x divide-gray-200 ${
                      isInCurrentWeek(onCall.start_date, onCall.end_date)
                        ? 'bg-green-200'
                        : ''
                    }`}>
                    <td className="py-4 px-3 text-sm font-medium text-gray-900 flex justify-center items-center">
                        <TrashIcon className="h-5 text-red-400 hover:cursor-pointer"
                                 onClick={() => handleDelete(onCall.id)}/>
                    </td>
                    <td className="px-3 py-4 text-sm text-gray-500">
                      ({onCall.employee_id}) | {onCall.employee_name}
                    </td>
                    <td className="px-3 py-4 text-sm text-gray-500">
                      <DateFormat date={onCall.start_date}/>
                    </td>
                    <td className="px-3 py-4 text-sm text-gray-500">
                      <DateFormat date={onCall.end_date}/>
                    </td>
                    <td className="px-3 py-4 text-sm text-gray-500">
                      {onCall.phone}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {isModalOpen && (
      <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75">
        <div className="bg-white p-6 rounded shadow-lg w-1/3">
          <h2 className="text-lg font-semibold mb-4">Add On Call Tech</h2>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleAddTech();
            }}
          >
            {/* Employee Selector */}
            <label htmlFor="employee" className="block text-sm font-medium text-gray-700">
              Select Employee
            </label>
            <select
              id="employee"
              className="block mb-4 w-full border border-gray-300 rounded-md px-4 py-2"
              value={newTech.employee_id || ''}
              onChange={(e) => {
                const selectedEmployee = employees.find(
                  (emp) => emp.id === parseInt(e.target.value)
                );
                setNewTech((prev) => ({
                  ...prev,
                  employee_id: e.target.value,
                  employee_name: selectedEmployee
                    ? `${selectedEmployee.first_name} ${selectedEmployee.last_name}`
                    : '',
                  phone: selectedEmployee ? selectedEmployee.phone : '',
                }));
              }}
            >
              <option value="">Select Employee</option>
              {employees.length > 0 ? (
                employees.map((employee) => (
                  <option key={employee.id} value={employee.id}>
                    {employee.first_name} {employee.last_name}
                  </option>
                ))
              ) : (
                <option value="" disabled>
                  No employees found
                </option>
              )}
            </select>

            {/* Start Date */}
            <label htmlFor="start_date" className="block text-sm font-medium text-gray-700">
              Start Date
            </label>
            <input
              id="start_date"
              type="date"
              className="block mb-4 w-full border border-gray-300 rounded-md px-4 py-2"
              value={newTech.start_date}
              onChange={(e) =>
                setNewTech((prev) => ({ ...prev, start_date: e.target.value }))
              }
            />

            {/* End Date */}
            <label htmlFor="end_date" className="block text-sm font-medium text-gray-700">
              End Date
            </label>
            <input
              id="end_date"
              type="date"
              className="block mb-4 w-full border border-gray-300 rounded-md px-4 py-2"
              value={newTech.end_date}
              onChange={(e) =>
                setNewTech((prev) => ({ ...prev, end_date: e.target.value }))
              }
            />

            {/* Phone */}
            <label htmlFor="phone" className="block text-sm font-medium text-gray-700">
              Phone
            </label>
            <input
              id="phone"
              type="text"
              placeholder="Phone"
              className="block mb-4 w-full border border-gray-300 rounded-md px-4 py-2"
              value={newTech.phone}
              readOnly // Phone field is auto-updated and read-only
            />

            {/* Submit and Cancel Buttons */}
            <div className="flex justify-end">
              <button
                type="submit"
                className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
              >
                Submit
              </button>
              <button
                type="button"
                className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 ml-2"
                onClick={() => setIsModalOpen(false)}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
      )}
    </>
  );
};

export default OnCallList;