import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { set } from 'date-fns';
import HeadingCp from '../components/HeadingCp';

const TechOvertimeDetail = () => {
  const [overtimeWork, setOvertimeWork] = useState('no');
  const [jobNumber, setJobNumber] = useState('');
  const [jobAddress, setJobAddress] = useState('');
  const [techNeed, setTechNeed] = useState('');
  const [jobCheckResult, setJobCheckResult] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [overtimeData, setOvertimeData] = useState([]);
  const [username, setUsername] = useState('JohnDoe');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;
  const [saturdayData, setSaturdayData] = useState([]);
  const [onCallDate, setOnCallDate] = useState([]);
  const [onCall, setOnCall] = useState(false);
  const [otData, setOtData] = useState([]); // not used if you only show overtimeData
  const [pmUser, setPmUser] = useState([]);
  const [day, setDay] = useState('');
  const [date, setDate] = useState('');
  const [ID, setID] = useState('');
  const [rowIndex, setRowIndex] = useState(null);

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  useEffect(() => {
    fetchOvertime();
  }, []);

  const fetchOvertime = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${apiUrl}/employee_crud.php`, {
        params: { getOvertimeUser: true },
      });
      if (response.data[0] === 'success') {
        setPmUser(response.data[1]);         // e.g. "Lead Technician" or null
        setOvertimeData(response.data[2]);   // array of items
        setUsername(response.data[3]);       // e.g. "jrezner"
        setOnCallDate(response.data[4]);     // e.g. [ ... ]
        setOnCall(response.data[6]);         // boolean or false
      } else {
        console.error('Error fetching overtime data');
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error("Session expired. Please login again.", { position: "top-center" });
        navigate('/login');
      } else if (error.response && error.response.status === 403) {
        toast.error("Access denied.", { position: "top-center" });
      } else {
        toast.error("Error fetching overtime data", { position: 'top-center' });
      }
    } finally {
      setLoading(false);
    }
  };

  const handleJobNumberChange = async(newVal) => {
    setJobNumber(newVal);
    if (newVal.length >= 7) {
      try {
        const response = await axios.get(`${apiUrl}/employee_crud.php`, {
          params: { checkJobNumber: newVal },
        });
        if (response.data.success) {
          setJobCheckResult('success');
          setJobNumber(response.data.success.job_number);
          setJobAddress(response.data.success.job_site);
        } else {
          toast.error('Job number not found', { position: 'top-center' });
          setJobCheckResult(null);
        }
      } catch (error) {
        console.error('Error checking job number:', error);
      }
    } else {
      setJobCheckResult(null);
    }
  };

  const handleTechNeedChange = (val) => {
    if(val > 5) {
      toast.error('Tech need should be less than 5', { position: "top-center" });
      setTechNeed('');
      return
    }
    setTechNeed(val);
  };

  const submitSaturday = async () => {
        const bodyData = { 
        status: 'submitOvertimeAvailable', 
        id: ID ? parseInt(ID) : null, 
        available: 1, 
        tech_need: techNeed, 
        job_num: jobNumber,
        job_address: jobAddress,
        schedule: overtimeWork === 'yes' ? 'yes' : 'no',
        date: date
      }

      try {
        const response = await axios.post(`${apiUrl}/employee_crud.php`, bodyData, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        if(response.data[0] == 'success') {
          setOvertimeData(prev => {
            const newData = [...prev];
            if(rowIndex !== null) {
              newData[rowIndex] = {
                ...newData[rowIndex],
                id: response.data[1].id,
                available: 1,
                status:'pending'
              };
            }
            return newData;
          });
          closeModal();
          setDay('');
          setTechNeed('');
          setJobNumber('');
        } else if(response.data[0] == 'deleted') {
          setOvertimeData(prev => {
            const newData = [...prev];
            if (rowIndex !== null) {
              newData[rowIndex] = {
                ...newData[rowIndex],
                id: null,
                available: 0,
                status: ''
              };
            }
            return newData;
          });
          closeModal();
        } else {
          console.error('Error submitting overtime:', response.data);
        }
      } catch (error) {
        console.error('Error submitting overtime:', error);
      }
  };

  const handleOvertimeItemClick = async (item, index) => {
    const available = item.available ? 1 : '';
    if(item.status === 'scheduled') {
      toast.error('You can’t submit overtime availability for scheduled day', { position: "top-center" });
      return
    }

    if(onCall === true) { 
      toast.error('You are on call, you can’t submit overtime', { position: "top-center" });
      return
    }
    if(pmUser === 'regular') {
      const bodyData = { 
        status: 'submitOvertimeAvailable', 
        id: item.id ? parseInt(item.id) : null, 
        available: available, 
        tech_need: 1, 
        job_num: '',
        job_address: '',
        schedule: 'no',
        date: item.date
      }
      try {
        const response = await axios.post(`${apiUrl}/employee_crud.php`, bodyData, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        if(response.data[0] == 'success') {
          setOvertimeData(prev => {
            const newData = [...prev];
            newData[index] = {
              ...newData[index],
              id: response.data[1].id,
              available: 1,
              status:'pending'
            };
            return newData;
          });
        } else if(response.data[0] == 'deleted') {
          setOvertimeData(prev => {
            const newData = [...prev];
            newData[index] = {
              ...newData[index],
              id: null,
              available: 0,
              status:''
            };
            return newData;
          });
        } else {
          console.error('Error submitting overtime:', response.data);
        }
      } catch (error) {
        console.error('Error submitting overtime:', error);
      }
    } else {
      setOvertimeWork(item.status === 'scheduled' ? 'yes' : 'no');
      setRowIndex(index);
      setDay(item.days);
      setDate(item.date);
      setID(item.id);
      openModal();      
    }
  };

  return (
    <div className="p-4">
      <HeadingCp label="Overtime Availability" />

      {loading && <p className="text-gray-500">Loading...</p>}

      {modalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded shadow-lg w-11/12 max-w-md p-4 relative">
            <div className="flex justify-between items-center border-b pb-2">
              <h5 className="text-lg font-bold">{day}</h5>
              <button onClick={closeModal} className="text-gray-500 hover:text-gray-800">
                &times;
              </button>
            </div>

            <div className="mt-4">
              <p className="text-base mb-4 font-semibold">
                Do you have any work scheduled?
              </p>

              <label className="flex items-center mb-2 cursor-pointer">
                <input
                  type="radio"
                  name="work_schedule"
                  value="yes"
                  checked={overtimeWork === 'yes'}
                  onChange={() => setOvertimeWork('yes')}
                  className="form-radio h-5 w-5 text-blue-600"
                />
                <span className="ml-2 text-sm">Yes</span>
              </label>

              <label className="flex items-center mb-4 cursor-pointer">
                <input
                  type="radio"
                  name="work_schedule"
                  value="no"
                  checked={overtimeWork === 'no'}
                  onChange={() => setOvertimeWork('no')}
                  className="form-radio h-5 w-5 text-blue-600"
                />
                <span className="ml-2 text-sm">No</span>
              </label>

              {overtimeWork === 'yes' && (
                <div className="mb-4">
                  <p className="text-base font-semibold mb-2">Add JOB# (required)</p>
                  <input
                    type="text"
                    value={jobNumber}
                    onChange={(e) => handleJobNumberChange(e.target.value)}
                    minLength={8}
                    maxLength={14}
                    className={`border rounded w-full p-2 text-sm ${jobCheckResult ? 'border-green-700' : 'focus:border-red-700 border-red-700  border-2'}`}
                    placeholder="Enter Job Number..."
                  />
                  {jobNumber.length > 0 && jobNumber.length < 7 && (//24B0101
                    <p className="text-red-500 text-xs mt-1">Job number is too short.</p>
                  )}
                </div>
              )}

              {overtimeWork === 'yes' && jobCheckResult && (
                <div className="mb-4">
                  <p className="text-base font-semibold mb-2">
                    How many techs needed (including yourself) for {jobNumber}?
                  </p>
                  <input
                    type="number"
                    value={techNeed}
                    onChange={(e) => handleTechNeedChange(e.target.value)}
                    className="border rounded w-full p-2 text-sm"
                    placeholder="Tech needed..."
                  />
                </div>
              )}
            </div>

            <div className="flex justify-end mt-4 pt-2 border-t">
              <button
                onClick={closeModal}
                className="bg-gray-300 hover:bg-gray-400 text-black py-1 px-3 rounded mr-2 text-sm"
              >
                Close
              </button>
              <button
                onClick={submitSaturday}
                className="bg-blue-500 hover:bg-blue-600 text-white py-1 px-4 rounded text-sm"
                disabled={overtimeWork === 'yes' && jobNumber.length < 8}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      )}

      <div className="mt-8 space-y-4">
        {overtimeData.map((item, index) => (
          <div
            key={`${item.date}-${index}`}
            className={`p-2 border rounded flex items-center justify-between ${
              item.status === 'scheduled' ? 'text-gray-400' : 'text-black'
            }`}
          >
            <div className="flex items-center space-x-4 cursor-pointer"
              onClick={() => handleOvertimeItemClick(item, index)} >
              {item.available === 1 ? (
                <svg
                  className="text-green-600 w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" d="M9 11l3 3L22 4" />
                </svg>
              ) : (
                <svg
                  className="text-green-600 w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                >
                  <rect x="2" y="2" width="20" height="20" rx="2" ry="2"></rect>
                </svg>
              )}
              <div className="flex flex-col">
                <span className="font-bold text-green-700">
                  {item.days} {item.days_date}
                </span>
                <span className="text-sm">{item.status}</span>
              </div>
            </div>

             {/* {item.available === 1 ? 'Remove' : 'Submit'} */}
            
          </div>
        ))}
      </div>
    </div>
  );
};

export default TechOvertimeDetail;
