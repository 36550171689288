import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import CurrencyFormat from '../components/CurrencyFormat';
import { ArrowPathRoundedSquareIcon } from '@heroicons/react/24/outline';
import axios from 'axios'; // Make sure Axios is imported
import SearchBarCP from '../components/SearchBarCP';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { UtilConvertDateTodayDate } from '../utils/UtilConvertDateTodayDate';
import DateFormat from '../components/DateFormat';
import LoadingSpinner from '../components/LoadingSpinner';
import HeadingCp from '../components/HeadingCp';

const ProposalListPage = () => {
  const navigate = useNavigate();
  const [proposals, setProposals] = useState([]);
  const [filteredProposals, setFilteredProposals] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    fetchProposals();
  }, []);


  const fetchProposals = async () => {
    try {
      const response = await axios.get(`${apiUrl}/proposal_list_crud.php?getList=1`);
      //console.log(response.data);//delete
      
      setProposals(response.data);
      setFilteredProposals(response.data); // Initially, filtered proposals are the same as fetched
    } catch (error) {
      ////console.error('Error fetching data', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearchChange = (event) => {
     const value = event.target.value;
    setSearchTerm(value);
    if (value === '') {
        setFilteredProposals(proposals);
    } else {
        const filtered = proposals.filter(proposal =>
            (proposal.description && proposal.description.toLowerCase().includes(value.toLowerCase())) ||
            (proposal.customer_name && proposal.customer_name.toLowerCase().includes(value.toLowerCase())) ||
            (proposal.job_address && proposal.job_address.toLowerCase().includes(value.toLowerCase())) ||
            (proposal.jobnum && proposal.jobnum.toLowerCase().includes(value.toLowerCase()))
        );
        setFilteredProposals(filtered);
    }
  };

  const handleSearchClick = async () => {
    setLoading(true);
    const bodyData = {
      status: 'search',
      data: { search: searchTerm }
    };
    try {
      const response = await axios.post(`${apiUrl}/proposal_list_crud.php`, bodyData, {
        headers: { 'Content-Type': 'application/json' }
      });

      if (response.data && Array.isArray(response.data)) {

        setProposals(response.data);
        setFilteredProposals(response.data);
      } else {
        handleSearchChange()
        setProposals([]);
        setFilteredProposals([]);
        toast.error('not found '+searchTerm, {
          position: 'top-center'
        });
      }
      // if(response.data){
      //   setProposals(response.data);
      //   setFilteredProposals(response.data);
      // } else {
      //   toast.error('no data found with '+searchTerm, {
      //     position: 'top-center'
      //   })
      // }

    } catch (error) {
      ////console.error('Error searching proposals', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
       
      <div className="mx-auto max-w-[1250px] px-4 sm:px-6 lg:px-8 py-8">
        {/* <h1 className="text-2xl font-semibold text-gray-500 mb-8">Proposal List</h1> */}
        <HeadingCp label={'Proposal List'} />
        <div className="flex space-x-3 items-center mb-10">

            <NavLink to="/job_gen">
              <button type="button"
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-400 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                New Job Number
              </button>
            </NavLink>

            <NavLink to="/proposal/new/active">
              <button type="button"
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-green-500 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
                + Active Proposal
              </button>
            </NavLink>

          <SearchBarCP search={searchTerm} handleChange={handleSearchChange} onClick={handleSearchClick} />

        </div>

        {loading ? (
          <LoadingSpinner children={'Loading...'}/>
        ) : (
          <table className="min-w-full divide-y divide-gray-300 bg-gray-200 border rounded-lg">
            <thead>
              <tr className='divide-x divide-gray-400'>
                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-2">
                  ID/Edit
                </th>
                <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell">
                  Date
                </th>
                <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">
                  Company | Job Site
                </th>
                {/* <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">
                  Job Address
                </th> */}
                <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">
                  Description
                </th>
                <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">
                  Price
                </th>
                <th scope="col" className="hidden pl-2 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">
                  Sent to
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Job#
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Generate
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredProposals.map((proposal, index) => (
                <tr key={proposal.id} className={`${proposal.void ? 'line-through text-red-600' : ''} ${index % 2 === 0 ? 'bg-white' : 'bg-gray-100'} divide-x`}>
                  <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-700 sm:w-auto sm:max-w-none sm:pl-2">
                        {
                          proposal.revised_id ? (
                            proposal.id
                          ) : proposal.changed_order_Id > 1 ? (
                            proposal.id
                          ) : (
                            <NavLink to={`/proposal/edit/${proposal.id}`} className="text-blue-700">{proposal.id}</NavLink>
                          )
                        }
                      <br />
                     <dl className="font-normal lg:hidden">
                      <dd className="mt-1 truncate text-gray-700">{proposal.customer_name}</dd>
                      <dd className="mt-1 truncate text-gray-500 sm:hidden">{proposal.job_address}</dd>
                      <dd className="mt-1 truncate text-gray-500 sm:hidden">
                        {proposal.date}
                      </dd>
                    </dl>
                  </td>
                  <td className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">
                    <DateFormat date={proposal.date} />
                  </td>
                  <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                    <p className='text-gray-900 font-bold'>{proposal.all_bidder ? ('All Bidders') : (proposal.customer_name)}</p>
                    <p>{proposal.job_address}</p>
                  </td>
                  {/* <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">{proposal.job_address}</td> */}
                  <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell whitespace-pre-wrap">
                    {proposal.description}
                  </td>
                  <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">{<CurrencyFormat value={proposal.price}/>}</td>
                  <td className="hidden pl-2 py-4 text-sm text-gray-500 sm:table-cell">{proposal.sentto}</td>
                  <td className="py-4 pl-1 text-sm text-gray-500">
                    {proposal.jobnum}
                    </td>
                  <td className="py-4 pl-1 text-sm text-gray-500">
                    {!proposal.approved && !proposal.void && !proposal.all_bidder && (
                       <NavLink to={`/wip/new/${proposal.jobnum}`}>
                          <ArrowPathRoundedSquareIcon className='max-w-[30px] mx-auto text-blue-600'/>
                        </NavLink>
                      )                
                    }
                    {proposal.approved && !proposal.void && (
                      <span className='font-bold text-gray-700'>Approved</span>
                      )                
                    }
                    {/* {proposal.void && !proposal.approved && (
                      <span className='font-bold text-gray-700'>Void</span>
                      )                
                    } */}
                  </td>

                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </>
  );
}

export default ProposalListPage;
