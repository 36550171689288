import React, { useState, useEffect } from 'react';

const InventoryTranModal = ({
  isOpen,
  onClose,
  onSubmit,
  initialData = {}
}) => {
  const isEdit = !!initialData.id;

  // States for each field
  const [id, setId] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [jobnum, setJobnum] = useState('');
  const [tenant, setTenant] = useState('');
  const [address, setAddress] = useState('');
  const [techInspection, setTechInspection] = useState('');
  const [startTime, setStartTime] = useState('TO FOLLOW');
  const [inspector, setInspector] = useState('');
  const [notes, setNotes] = useState('');
  const [date, setDate] = useState('');

  // If "edit" is present, prefill from initialData
  useEffect(() => {
    if (isEdit) {
      setId(initialData.id || null);
      setFileName(initialData.file_name || null);
      setJobnum(initialData.jobnum || '');
      setTenant(initialData.tenant || '');
      setAddress(initialData.address || '');
      setTechInspection(initialData.tech_inspection || '');
      setStartTime(initialData.start_time || 'TO FOLLOW');
      setInspector(initialData.inspector || '');
      setNotes(initialData.notes || '');
      setDate(initialData.date || '');
    } else {
      setId(null);
      setFileName(null);
      setJobnum('');
      setTenant('');
      setAddress('');
      setTechInspection('');
      setStartTime('TO FOLLOW');
      setInspector('');
      setNotes('');
      setDate('');
    }
  }, [isEdit, initialData]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = {
      data: {
        id,
        jobnum,
        tenant,
        address,
        tech_inspection: techInspection,
        start_time: startTime,
        inspector,
        notes,
        date
      },
      status: 'submit'
    };
    onSubmit(formData);
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded shadow-lg w-11/12 max-w-2xl p-6 relative">
        <div className="flex justify-between items-center border-b pb-2">
          <h3 className="text-lg font-bold">
            {isEdit ? 'Edit Inspection' : 'New FDNY Inspection'}
          </h3>
          <button
            onClick={onClose}
            className="text-gray-800 hover:text-gray-800"
          >
            &times;
          </button>
        </div>

        <form onSubmit={handleSubmit} className="mt-4 space-y-4">
          <p className="text-sm text-gray-800">* Denotes Required Field</p>

          {isEdit && (
            <>
              <input type="hidden" value={id || ''} readOnly />
              <input type="hidden" value={fileName || ''} readOnly />
            </>
          )}

          <div className="flex space-x-4 items-center pb-2">
            <div className="flex flex-col w-1/2">
              <label className="block text-gray-800">
                Date <span className="text-red-600">*</span>
              </label>
              <input
                type="date"
                name="date"
                value={date}
                onChange={(e) => setDate(e.target.value)}
                className="border rounded w-full p-2 mt-1 text-sm"
                required
              />
            </div>
            <div className="flex flex-col w-1/2">
              <label className="block text-gray-800">
                Job # <span className="text-red-600">*</span>
              </label>
              <input
                type="text"
                name="jobnum"
                maxLength={255}
                value={jobnum}
                onChange={(e) => setJobnum(e.target.value)}
                className="border rounded w-full p-2 mt-1 text-sm"
                placeholder="JOB#"
              />
            </div>
          </div>

          <div className="flex space-x-4 items-center">
            <div className="flex flex-col w-1/4">
              <label className="block text-gray-800 mb-1">Tenant</label>
              <input
                type="text"
                name="tenant"
                maxLength={255}
                value={tenant}
                onChange={(e) => setTenant(e.target.value)}
                className="border rounded p-2 text-sm"
                placeholder="Tenant's name"
              />
            </div>
            <div className="flex flex-col w-3/4">
              <label className="block text-gray-800 mb-1">
                Project Address <span className="text-red-600">*</span>
              </label>
              <input
                type="text"
                name="address"
                maxLength={255}
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                className="border rounded p-2 text-sm"
                placeholder="Job Site Address"
              />
            </div>
          </div>

          <div className="flex space-x-4 items-center">
            <div className="flex flex-col w-1/4">
              <label className="block text-gray-800">Time</label>
              <select
                name="start_time"
                value={startTime}
                onChange={(e) => setStartTime(e.target.value)}
                className="border rounded p-2 mt-1 text-sm"
              >
                {isEdit && <option value={startTime}>{startTime}</option>}
                <option value="TO FOLLOW">TO FOLLOW</option>
                <option value="7:00 AM">7:00 AM</option>
                <option value="8:00 AM">8:00 AM</option>
                <option value="9:00 AM">9:00 AM</option>
                <option value="10:00 AM">10:00 AM</option>
                <option value="11:00 AM">11:00 AM</option>
                <option value="12:00 PM">12:00 PM</option>
                <option value="1:00 PM">1:00 PM</option>
                <option value="2:00 PM">2:00 PM</option>
                <option value="3:00 PM">3:00 PM</option>
                <option value="4:00 PM">4:00 PM</option>
                <option value="5:00 PM">5:00 PM</option>
                <option value="6:00 PM">6:00 PM</option>
                <option value="7:00 PM">7:00 PM</option>
                <option value="8:00 PM">8:00 PM</option>
                <option value="9:00 PM">9:00 PM</option>
                <option value="10:00 PM">10:00 PM</option>
                <option value="11:00 PM">11:00 PM</option>
              </select>
            </div>
            <div className="flex flex-col w-1/4">
              <label className="block text-gray-800">
                Tech <span className="text-red-600">*</span>
              </label>
              <input
                type="text"
                name="tech_inspection"
                maxLength={20}
                value={techInspection}
                onChange={(e) => setTechInspection(e.target.value)}
                className="border rounded p-2 mt-1 text-sm"
                placeholder="Tech's name"
              />
            </div>
            <div className="flex flex-col w-1/2">
              <label className="block">Inspector</label>
              <input
                type="text"
                name="inspector"
                value={inspector}
                onChange={(e) => setInspector(e.target.value)}
                className="border rounded w-full p-2 mt-1 text-sm"
                placeholder="Inspector's name"
              />
            </div>
          </div>

          <div>
            <label className="block text-gray-800">Notes</label>
            <textarea
              rows={4}
              name="notes"
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
              className="border rounded w-full p-2 mt-1 text-sm resize-none"
              placeholder="Any additional info..."
            />
          </div>

          <div className="text-right">
            <button
              onClick={onClose}
              type="button"
              className="bg-gray-500 hover:bg-gray-600 text-white py-1 px-4 rounded text-sm mr-3"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-blue-500 hover:bg-blue-600 text-white py-1 px-4 rounded text-sm"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default InventoryTranModal;
