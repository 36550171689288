// components/DevicesTested.jsx
import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { TrashIcon } from '@heroicons/react/24/outline';

/**
 * Props:
 *  - deviceList: an object like { "Smoke Detector": { id, status, issues: [...], ... }, "Heat Detector": {...}, ... }
 *  - setDeviceList: function to update deviceList
 *  - urlID: the inspection ID from the route or parent state
 *  - ready, setReady: booleans or flags indicating if all devices are tested
 *  - setShowLogBookBox, setShowCustomerEmailBox: optional state toggles (unused if you don’t need them)
 */
const DevicesTested = ({
  deviceList,
  setDeviceList,
  urlID,
  ready,
  setReady,
  setShowLogBookBox,
  setShowCustomerEmailBox
}) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();

  const [expandedIndex, setExpandedIndex] = useState(null);
  const [formData, setFormData] = useState({});

  /**
   * Toggle expansion for the device at index
   */
  const toggle = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  /**
   * Add or update the device status (yes/no/na)
   */
  const addDevice = async (deviceKey, action, id) => {
    const bodyData = {
      status: 'addDevice',
      device: deviceKey,
      action: action,
      id: id, // deviceList entry ID, if any
      inspection_id: urlID
    };

    try {
      const response = await axios.post(`${apiUrl}/contracts_crud.php`, bodyData, {
        headers: { 'Content-Type': 'application/json' }
      });

      console.log('addDevice response:', response.data);

      if (response.data[0] === 'success') {
        // Example: response.data => ["success", {deviceObj}, readyValue, checklist]
        // Adjust indices as needed
        const object = response.data[1];
        const newReadyValue = response.data[2];   // if index 2 is a 'ready' boolean or something
        const checklist = response.data[3];

        // Update 'ready' if the backend returns it
        if (setReady && newReadyValue !== undefined) {
          setReady(newReadyValue);
        }

        // Add or update the device in local state
        if (!object || !object.device) {
          console.error('Server did not return the expected device object:', object);
          return;
        }

        setDeviceList((prev) => ({
          ...prev,
          [object.device]: {
            ...prev[object.device],
            id: object.id,
            device: object.device,
            status: object.status,
            comments: object.comments || [],
            device_checklist: checklist
          }
        }));
      } else {
        console.error('Error adding/updating device:', response.data);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error('Session expired. Please login again.', {
          position: 'top-center'
        });
        navigate('/login');
      } else if (error.response && error.response.status === 403) {
        toast.error("Oops!! you don't have access to this area. Speak to the CFO.", {
          position: 'top-center'
        });
      } else {
        console.error('Unexpected error in addDevice:', error);
      }
    }
  };

  /**
   * Add an issue to a device with status = "no"
   */
  const addIssue = async (deviceKey, index) => {
    const details = formData[deviceKey] || {};
    const payload = {
      status: 'addIssue',
      device_list_id: deviceList[deviceKey].id,
      device_location: details.device_location || '',
      device_address: details.device_address || '',
      device_model: details.device_model || '',
      device_color: details.device_color || '',
      device_issue: details.device_issue || ''
    };

    try {
      const response = await axios.post(`${apiUrl}/contracts_crud.php`, payload, {
        headers: { 'Content-Type': 'application/json' }
      });

      console.log('addIssue response:', response.data);

      if (response.data[0] === 'success') {
        // Example: response.data => ["success", newIssueID, something, updatedReadyValue, ...]
        const newIssueId = response.data[1];
        const updatedReadyValue = response.data[3]; // if index 3 is the "ready" status

        if (setReady && updatedReadyValue !== undefined) {
          setReady(updatedReadyValue);
        }

        // Update local state to add the new issue
        setDeviceList((prev) => {
          const updatedIssues = prev[deviceKey].issues
            ? [...prev[deviceKey].issues]
            : [];

          updatedIssues.push({
            id: newIssueId,
            device_address: payload.device_address,
            device_location: payload.device_location,
            model: payload.device_model,
            color: payload.device_color,
            issue: payload.device_issue
          });

          return {
            ...prev,
            [deviceKey]: {
              ...prev[deviceKey],
              issues: updatedIssues
            }
          };
        });

        // Clear the form data after adding the issue
        setFormData((prev) => ({ ...prev, [deviceKey]: {} }));
      } else {
        console.error('Error adding issue:', response.data);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error('Session expired. Please login again.', {
          position: 'top-center'
        });
        navigate('/login');
      } else if (error.response && error.response.status === 403) {
        toast.error("Oops!! you don't have access to this area. Speak to the CFO.", {
          position: 'top-center'
        });
      } else {
        console.error('Unexpected error in addIssue:', error);
      }
    }
  };

  /**
   * Delete an existing issue by ID
   */
  const handleDelete = async (issueID) => {
    try {
      const response = await axios.post(
        `${apiUrl}/contracts_crud.php`,
        {
          status: 'delIssue',
          issue_id: issueID // or data: {issue_id: issueID} if your backend expects that shape
        },
        {
          headers: { 'Content-Type': 'application/json' }
        }
      );

      console.log('deleteIssue response:', response.data);

      if (response.data[0] === 'success') {
        toast.success('Deleted', { position: 'top-center' });

        // Remove the deleted issue from deviceList in local state
        setDeviceList((prev) => {
          const newDeviceList = { ...prev };
          Object.keys(newDeviceList).forEach((dKey) => {
            const devItem = newDeviceList[dKey];
            if (devItem.issues) {
              newDeviceList[dKey] = {
                ...devItem,
                issues: devItem.issues.filter((iss) => iss.id !== issueID)
              };
            }
          });
          return newDeviceList;
        });
      } else {
        toast.error(response.data[1] || 'Failed to delete.', {
          position: 'top-center'
        });
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error('Session expired. Please login again.', {
          position: 'top-center'
        });
        navigate('/login');
      } else if (error.response && error.response.status === 403) {
        toast.error("Oops!! you don't have access to this area. Speak to the CFO.", {
          position: 'top-center'
        });
      } else {
        console.error('Unexpected error in handleDelete:', error);
      }
    }
  };

  return (
    <div className="mb-5">
      {/* <h5 className="font-bold text-gray-700 mb-3">
        All Devices Tested and Operational <span className="text-red-500 text-sm">(required)</span>
      </h5> */}

      {Object.keys(deviceList).map((deviceKey, index) => {
        const item = deviceList[deviceKey];

        return (
          <div key={index} className="border-b py-2">
            {/* Device row: Yes/No/NA Buttons */}
            <div className="flex items-center space-x-2">
              <button
                className={`rounded-full w-8 h-8 text-white ${item.status === 'yes' ? 'bg-green-700' : 'bg-green-500'}`}
                onClick={() => addDevice(deviceKey, 'yes', item.id)}
                disabled={item.status === 'yes'}
              >
                Y
              </button>
              <button
                className={`rounded-full w-8 h-8 text-white ${item.status === 'no' ? 'bg-red-700' : 'bg-red-500'}`}
                onClick={() => addDevice(deviceKey, 'no', item.id)}
                disabled={item.status === 'no'}
              >
                N
              </button>
              <button
                className={`rounded-full px-2 py-1 text-white ${item.status === 'na' ? 'bg-yellow-700' : 'bg-yellow-500'}`}
                onClick={() => addDevice(deviceKey, 'na', item.id)}
                disabled={item.status === 'na'}
              >
                N/A
              </button>

              {/* Expand/Collapse to show issues & form */}
              <span className="cursor-pointer" onClick={() => toggle(index)}>
                {deviceKey}
                {expandedIndex === index ? ' ▼' : ' ►'}
              </span>
              {item.status && <span className="text-green-500 text-sm ml-2">✔</span>}
            </div>

            {/* Issue List */}
            {expandedIndex === index && item.issues && item.issues.length > 0 && (
              <ul className="ml-10 mt-2 text-sm">
                {item.issues.map((iss, i) => (
                  <li key={i} className="flex justify-between mt-3">
                    <span
                      className="text-red-500 cursor-pointer"
                      onClick={() => handleDelete(iss.id)}
                      title="Delete Issue"
                    >
                      <TrashIcon className="h-5" />
                    </span>
                    <span>
                      {iss.device_address} | {iss.device_location} | {iss.color} | {iss.issue}
                    </span>
                  </li>
                ))}
              </ul>
            )}

            {/* If status is "no", show the Issue Form */}
            {expandedIndex === index && item.status === 'no' && (
              <div className="mt-3 bg-yellow-100 p-3">
                <div className="mb-2">
                  <input
                    type="text"
                    className="border p-1 w-full mb-1"
                    placeholder="DEVICE LOCATION"
                    value={formData[deviceKey]?.device_location || ''}
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        [deviceKey]: { ...prev[deviceKey], device_location: e.target.value }
                      }))
                    }
                  />
                  <input
                    type="text"
                    className="border p-1 w-full mb-1"
                    placeholder="DEVICE ADDRESS"
                    value={formData[deviceKey]?.device_address || ''}
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        [deviceKey]: { ...prev[deviceKey], device_address: e.target.value }
                      }))
                    }
                  />
                  <input
                    type="text"
                    className="border p-1 w-full mb-1"
                    placeholder="DEVICE MODEL"
                    value={formData[deviceKey]?.device_model || ''}
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        [deviceKey]: { ...prev[deviceKey], device_model: e.target.value }
                      }))
                    }
                  />
                  <select
                    className="border p-1 w-full mb-1"
                    value={formData[deviceKey]?.device_color || ''}
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        [deviceKey]: { ...prev[deviceKey], device_color: e.target.value }
                      }))
                    }
                  >
                    <option value="">SELECT COLOR</option>
                    <option value="RED">RED</option>
                    <option value="WHITE">WHITE</option>
                  </select>
                  <select
                    className="border p-1 w-full mb-1"
                    value={formData[deviceKey]?.device_issue || ''}
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        [deviceKey]: { ...prev[deviceKey], device_issue: e.target.value }
                      }))
                    }
                  >
                    <option value="">SELECT ISSUE</option>
                    <option value="NOT WORKING">NOT WORKING</option>
                    <option value="NOT ACTIVATING">NOT ACTIVATING</option>
                    <option value="TIME DELAY">TIME DELAY</option>
                    <option value="ACTIVE TROUBLE">ACTIVE TROUBLE</option>
                    <option value="PROGRAMMING">PROGRAMMING</option>
                    <option value="NEED ADJUSTMENT">NEED ADJUSTMENT</option>
                  </select>

                  <button
                    className="bg-blue-500 text-white py-1 px-3 rounded text-sm mt-2"
                    onClick={() => addIssue(deviceKey, index)}
                  >
                    Add Issue
                  </button>
                </div>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default DevicesTested;
