import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import DateFormat from '../components/DateFormat';
import TopTabs from '../components/TopTabs';
import { useNavigate } from 'react-router-dom';
import FileLocationCp from './FileLocationCp';
import Truncate from '../components/Truncate';
import ModalWindow from '../components/ModalWindow';
import LoadingSpinner from './LoadingSpinner';
import SearchBarCP from '../components/SearchBarCP';
import SubMenu from './SubMenu';
import FDNYNewInspectionModal from './FDNYNewInspectionModal';
import FileLocation from './FileLocation';

const FDNYlistCp = () => {
  const [inspectionData, setInspectionData] = useState([]);
  const [filterFdnyInspection, setFilterFdnyInspection] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [editData, setEditData] = useState(null);
  const [notesIdOpen, setNotesIdOpen] = useState(null);
  const [selectGenFile, setSelectGenFile] = useState(null);
  const [isModalFileGenOpen, setIsModalFileGenOpen] = useState(false);
  const [file, setFile] = useState(null);

  const FileTypes = ['B45', 'LOD', 'LOR', 'LOA', 'VO'];
  const fileMap = {};

  const apiUrl = process.env.REACT_APP_API_URL;
  const api = `${apiUrl}/fdny_crud.php`;
  const navigate = useNavigate();

  useEffect(() => {
    fetchDates();
  }, []);

  const fetchDates = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${apiUrl}/fdny_crud.php?list=true`, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
      });
      if (response.data[0] === 'success') {
        setInspectionData(response.data[1]);
        setFilterFdnyInspection(response.data[1]);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate('/login');
      } else if (error.response && error.response.status === 403) {
        toast.error("Oops!! you don't have access to this area speak to the CFO.", {
          position: "top-center",
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    if (value === '') {
      setFilterFdnyInspection(inspectionData);
    } else {
      const filtered = inspectionData.filter(
        (item) =>
          (item.employee_name && item.employee_name.toLowerCase().includes(value.toLowerCase())) ||
          (item.date_out && item.date_out.toLowerCase().includes(value.toLowerCase()))
      );
      setFilterFdnyInspection(filtered);
    }
  };

  const handleNotesView = (id) => {
    setNotesIdOpen(id);
  };

  const handleSearchClick = async () => {
    setLoading(true);
    const bodyData = {
      status: 'searchCall',
      data: { search: searchTerm },
    };
    try {
      const response = await axios.post(api, bodyData, {
        headers: { 'Content-Type': 'application/json' },
      });
      if (response.data && Array.isArray(response.data)) {
        setFilterFdnyInspection(response.data[1]);
      } else {
        setFilterFdnyInspection([]);
        toast.error('Not found: ' + searchTerm, { position: 'top-center' });
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate('/login');
      }
    } finally {
      setLoading(false);
    }
  };

  const getLinksForJob = (object) => {
    // 1) Check the object.files array for each file_name type
    //    We’ll define some file types: B45, LOD, LOR, LOA, VO, etc.
    //    If a matching file exists, we rename/call delete; otherwise we set for upload.

    // Helper function to see if object.files includes a certain filename
    const hasFile = (filename) => {
      return object.files && object.files.some(file => file.file_name === filename);
    };

    // Also define a function to handle deleting
    const handleDeleteFile = async(fdnyFileId) => {
      try {
            const response = await axios.post( `${apiUrl}/fdny_crud.php`, {
              status: 'deleteFile',
              data: { file_id: fdnyFileId },
            });
            if(response.data.success) {
              toast.success('File deleted successfully', { position: 'top-center' });
              fetchDates();
            }
      } catch (error) {
        toast.error('Error deleting file', { position: 'top-center' });
      }
    };

    const buildFileLink = (filename) => {
      // If the file_name is found in object.files
      const existingFile = object.files?.find(f => f.file_name === filename);

      if (existingFile) {
        // If file exists, show 'Delete'
        return {
          name: `Delete ${filename} File`,
          click: () => handleDeleteFile(existingFile.id),
          key: `${filename}-${object.id}`
        };
      } else {
        // If file does not exist, show 'Upload'
        return {
          name: `Upload ${filename} File`,
          click: () => {
            setIsModalFileGenOpen(true);
            setSelectGenFile({ id: object.id, type: filename });
          },
          key: `${filename}-${object.id}`
        };
      }
    };

    return [
      {
        name: 'Edit Inspection',
        click: () => openNewInspection(object),
      },
      buildFileLink('B45'),
      buildFileLink('LOD'),
      buildFileLink('LOR'),
      buildFileLink('LOA'),
      buildFileLink('VO'),
    ];
  };

  const openNewInspection = (inspectionObject = null) => {
    setEditData(inspectionObject);
    setShowModal(true);
  };

  const handleSubmitInspection = async (formData) => {
    if (
      !formData.data.address ||
      !formData.data.tech_inspection ||
      !formData.data.start_time ||
      !formData.data.jobnum ||
      !formData.data.date 
    ) {
      toast.error('Please fill all the required fields', { position: 'top-center' });
      return;
    }

    try {
      const response = await axios.post(`${apiUrl}/fdny_crud.php`, formData, {
        headers: { 'Content-Type': 'application/json' },
      });
      if (response.data.success) {
        toast.success('Inspection saved', { position: 'top-center' });
        setShowModal(false);
        fetchDates(); 
      } else {
        toast.error('Error saving inspection', { position: 'top-center' });
      }
    } catch (error) {
      toast.error('Error saving inspection '+error, { position: 'top-center' });
    }
  };

  const handleGenUploadFile = async (e) => {
    e.preventDefault();
    if (!file) {
      toast.error("Please select a file before uploading.", { position: 'top-center' });
      return;
    }
    
    const formData = new FormData();
    formData.append('status', 'submit_gen_file');
    formData.append('fdny_inspection_id', selectGenFile.id); // Include the accounting vendor ID
    formData.append('type', selectGenFile.type); // Include the accounting vendor ID
    formData.append('file', file);
    try {
      const response = await axios.post(`${apiUrl}/fdny_file_gen.php`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      if (response.data.success) {
        toast.info('File uploaded successfully!', { position: 'top-center' });

        // Update the state with the new file information
        setFilterFdnyInspection(prevItems =>
            prevItems.map(item =>
              item.id === selectGenFile ? { ...item, file_name: response.data[0] } : item // Set TECH to full name
            )
        );

        setFile(null);
        setIsModalFileGenOpen(false);
      } else {
        toast.error(response.data.error || "Error uploading file", { position: 'top-center' });
      }
    } catch (error) {
      toast.error("An error occurred during file upload.", { position: 'top-center' });
    }
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (!selectedFile) {
      toast.error("No file selected.", { position: 'top-center' });
      setFile(null);
      return;
    }

    const validFileTypes = ['application/pdf'];
    const maxSize = 2 * 1024 * 1024; // 2MB in bytes

    if (!validFileTypes.includes(selectedFile.type)) {
      toast.error("Invalid file type. Only PDF is allowed.", { position: 'top-center' });
      setFile(null);
      return;
    }

    if (selectedFile.size > maxSize) {
      toast.error("File size exceeds 2MB.", { position: 'top-center' });
      setFile(null);
      return;
    }

    setFile(selectedFile);
  };  

  return (
    <>
      <div className="mx-auto max-w-6xl sm:px-6 lg:px-8 py-10">
        <div className="flex space-x-3 items-center mb-10">
          <div className="flex-grow mb-3">
            <SearchBarCP
              search={searchTerm}
              handleChange={(e) => setSearchTerm(e.target.value)}
              onClick={handleSearchClick}
            />
          </div>
        </div>

        {loading ? (
          <LoadingSpinner>Loading...</LoadingSpinner>
        ) : (
          <div>
            <table className="w-full divide-y divide-gray-300 bg-gray-200 border rounded-lg">
              <thead>
                <tr className="divide-x divide-gray-400">
                  <th scope="col" className="py-3 pl-4 pr-3 text-left text-sm font-semibold text-gray-900">
                    ID | Job#
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Tenant | Job Site
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Tech
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Time | Inspector
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Date
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    File Name
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Notes
                  </th>
                </tr>
              </thead>
              <tbody>
                {filterFdnyInspection.map((inspection, index) => 
                {
                  //  For each row, build a fileMap from that row's "files" array
                  const fileMap = {};
                  if (inspection.files && Array.isArray(inspection.files)) {
                    inspection.files.forEach(fileObj => {
                      // e.g. "B45" => "fdny_3e97778976f6.pdf"
                      fileMap[fileObj.file_name] = fileObj.file_path;
                    });
                  }

                  return (
                    <tr key={inspection.id} className={`divide-x ${index % 2 === 0 ? 'bg-white' : 'bg-gray-100'}`}>
                      <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-700">
                        <SubMenu label={inspection.id} idItem={inspection.id} links={getLinksForJob(inspection)} />
                        <p className="text-xs text-green-600">{inspection.jobnum}</p>
                      </td>

                      <td className="px-3 py-4 text-sm text-gray-500">
                        <p className="text-xs text-gray-400">{inspection.tenant}</p>
                        <p className="font-bold">{inspection.address}</p>
                      </td>

                      <td className="px-3 py-4 text-sm text-gray-500">
                        {inspection.tech_inspection}
                      </td>

                      <td className="px-3 py-4 text-sm text-gray-500">
                        <p className="text-xs text-gray-500">{inspection.start_time}</p>
                        <p className="font-semibold">{inspection.inspector}</p>
                      </td>

                      <td className="px-3 py-4 text-sm text-gray-500">
                        {inspection.date}
                      </td>

                      <td className="px-3 py-4 text-sm text-gray-500">
                        {FileTypes.map(ft => (
                          <div key={ft} className="flex items-center space-x-2">
                            <span>{ft}</span>
                            <FileLocation expFile={fileMap[ft]} where="fdny_files" />
                          </div>
                        ))}
                      </td>

                      <td className="px-3 py-4 text-sm text-gray-500 max-w-xs">
                        {inspection.notes && (
                          <Truncate text={inspection.notes} handleNotesView={() => setNotesIdOpen(inspection.id)} />
                        )}
                        {notesIdOpen === inspection.id && (
                          <ModalWindow job={inspection.notes} closeNotes={() => setNotesIdOpen(null)} activeButton />
                        )}
                      </td>
                    </tr>
                  );
                }
                )}
              </tbody>
            </table>
          </div>
        )}
      </div>

      {/* File upload Modal */}
      {isModalFileGenOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-600 bg-opacity-50">
          <div className="bg-white p-8 rounded-md shadow-md min-w-[500px]">
            <h2 className="text-xl font-semibold mb-4">
              Upload {selectGenFile && selectGenFile.type} File
            </h2>
            <form onSubmit={handleGenUploadFile}>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                  Select a PDF file (Max size 2MB)
                </label>
                <input
                  type="file"
                  accept="application/pdf"
                  onChange={handleFileChange}
                  className="mt-2"
                />
              </div>
              <div className="flex justify-end">
                <button
                  type="button"
                  onClick={() => setIsModalFileGenOpen(false)}
                  className="px-4 py-2 mr-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700"
                >
                  Upload
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* FDNY Inspection Modal */}
      <FDNYNewInspectionModal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        onSubmit={handleSubmitInspection}
        initialData={editData || {}}/>
    </>
  )};

export default FDNYlistCp;