import React, { useState, useEffect } from 'react';
import LoadingSpinner from '../components/LoadingSpinner';
import DateFormat from '../components/DateFormat';
import { toast } from 'react-toastify';
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import SideNav from '../components/SideNav';
import HeadingCp from '../components/HeadingCp';
import AvatarLocation from '../components/AvatarLocation';
import DayOff from '../components/DayOff';
import Alerts from '../components/Alerts';

const CheckInOutPage = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const api = `${apiUrl}/tech_crud.php`;

  // State for search input
  const [searchTerm, setSearchTerm] = useState('');
  // Loading indicator
  const [loading, setLoading] = useState(false);
  // Will hold an array of employees, each with last_in / last_out
  const [checkInOutData, setCheckInOutData] = useState([]);
  // React Router
  const navigate = useNavigate();

  // Date input for filtering
  const [dateTo, setDateTo] = useState('');
  // List of all employees (for the filter dropdown)
  const [employees, setEmployees] = useState([]);
  // Selected employee ID for filtering
  const [filterEmployee, setFilterEmployee] = useState('');

  // =========================
  // 1. Fetch Full CheckIn/Out Data (Array of Employees)
  // =========================
  const fetchCheckInOutData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(api, {
        params: { vcheckinout: true },
        headers: { 'Content-Type': 'application/json' },
      });
      if (Array.isArray(response.data)) {
        // If the server returns an array (each element = employee + last_in + last_out)
        if (response.data.length === 0) {
          toast.error('No records found!', { position: 'top-center' });
        }
        setCheckInOutData(response.data);
      } else {
        // If we didn't get an array, handle it gracefully
        toast.error('Unexpected data format received!', { position: 'top-center' });
        setCheckInOutData([]);
      }
    } catch (error) {
      console.error(error);
      // Basic error handling...
      if (error.response && error.response.status === 401) {
        toast.error("Session expired. Please login again.", { position: "top-center" });
        navigate('/login');
      } else if (error.response && error.response.status === 403) {
        toast.error("Oops!! you don't have access to this area. Speak to the CFO.", {
          position: "top-center"
        });
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCheckInOutData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api, navigate]);

  // =========================
  // 2. Fetch Employees
  // =========================
  useEffect(() => {
    setLoading(true);
    const fetchEmployees = async () => {
      try {
        const response = await axios.get(`${apiUrl}/service_crud.php?employee_list=true`, {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
        });
        const employeeList = Array.isArray(response.data) ? response.data : [];
        if (employeeList.length === 0) {
          toast.error("No employees found!", { position: "top-center" });
        }
        setEmployees(employeeList);
      } catch (error) {
        console.error(error);
        if (error.response && error.response.status === 401) {
          toast.error("Session expired. Please login again.", {
            position: "top-center"
          });
          navigate('/login');
        } else if (error.response && error.response.status === 403) {
          toast.error("Oops!! you don't have access to this area. Speak to the CFO.", {
            position: "top-center"
          });
        }
      } finally {
        setLoading(false);
      }
    };
    fetchEmployees();
  }, [apiUrl, navigate]);

  // =========================
  // 3. Filter Logic (Optional)
  // =========================
  const handleFilter = async () => {
    if (!filterEmployee || !dateTo) {
      toast.error('Please select an employee and date.', { position: 'top-center' });
      return;
    }
    setLoading(true);

    const bodyData = {
      status: 'getFilter',
      date_to: dateTo,
      employee_id: filterEmployee,
    };

    try {
      const response = await axios.post(api, bodyData, {
        headers: { 'Content-Type': 'application/json' },
      });

      if (Array.isArray(response.data)) {
        if (response.data.length === 0) {
          toast.info("No records found for the selected criteria.", { position: "top-center" });
        }
        setCheckInOutData(response.data);
      } else {
        toast.error("Unexpected filter result format!", { position: "top-center" });
        setCheckInOutData([]);
      }
    } catch (error) {
      console.error(error);
      if (error.response && error.response.status === 401) {
        navigate('/login');
      } else if (error.response && error.response.status === 403) {
        toast.error("Oops!! you don't have access to this area. Speak to the CFO.", {
          position: "top-center"
        });
      }
    } finally {
      setLoading(false);
    }
  };

  // =========================
  // 4. Search Logic
  // =========================
  // Decide which fields to search:
  // We'll search in: employee's full name, and job_location (if in last_in or last_out)
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredData = checkInOutData.filter((emp) => {
    // Basic string building:
    const employeeName = emp.employee?.toLowerCase() || '';

    // For job locations, we can check both last_in and last_out
    const inLocation = emp.last_in?.job_location?.toLowerCase() || '';
    const outLocation = emp.last_out?.job_location?.toLowerCase() || '';

    const searchVal = searchTerm.toLowerCase();
    return (
      employeeName.includes(searchVal) ||
      inLocation.includes(searchVal) ||
      outLocation.includes(searchVal)
    );
  });

  // =========================
  // 5. Render
  // =========================
  return (
    <div className="max-w-[1220px] mx-auto grid grid-cols-1 md:grid-cols-[200px_minmax(540px,_1fr)_260px] gap-x-4">
      {/* Side Navigation */}
      <div className="hidden md:block">
        <div className='pt-6 pr-3'>
          <SideNav />
        </div>
      </div>

      {/* Main Content */}
      <div className="p-4">
        <div className='px-4'>
          {/* Heading */}
          <div className="sm:flex sm:items-center">
            <HeadingCp label={'Check In/Out Records'} />
          </div>

          {/* Search Bar */}
          <div className="sm:flex sm:items-center py-1">
            <div className="flex w-full items-center mt-5 sm:mt-0">
              <div className="flex-grow">
                <div className="flex space-x-3 items-center mb-8">
                  <div className="relative rounded-md shadow-sm flex-grow">
                    <input
                      type="text"
                      name="search"
                      value={searchTerm}
                      onChange={handleSearchChange}
                      className="block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
                      placeholder="Search by Name or Job Location"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Filter Bar */}
          <div className="flex items-center gap-4 max-w-6xl w-full mx-auto mb-6">
            <input
              type="date"
              className="border border-gray-300 rounded-md px-2 py-2"
              value={dateTo}
              onChange={(e) => setDateTo(e.target.value)}
            />

            <select
              className="border border-gray-300 rounded-md px-6 py-2"
              value={filterEmployee}
              onChange={(e) => setFilterEmployee(e.target.value)}
            >
              <option value="">Select Employee</option>
              {employees && employees.length > 0 ? (
                employees.map((employee) => (
                  <option key={employee.id} value={employee.id}>
                    {employee.first_name} {employee.last_name}
                  </option>
                ))
              ) : (
                <option value="" disabled>
                  No employees found
                </option>
              )}
            </select>

            <button
              type="button"
              onClick={handleFilter}
              className="px-4 py-2 border border-transparent text-sm font-medium rounded-md 
                shadow-sm text-white bg-blue-500 hover:bg-blue-700 focus:outline-none 
                focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Filter
            </button>

            {/* Reset Button */}
            <button
              type="button"
              onClick={fetchCheckInOutData}
              className="px-4 py-2 border border-transparent text-sm font-medium rounded-md 
                shadow-sm text-white bg-gray-500 hover:bg-gray-700 focus:outline-none 
                focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
            >
              Reset
            </button>
          </div>

          {/* Data Cards */}
          {loading ? (
            <LoadingSpinner>Loading...</LoadingSpinner>
          ) : (
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
              {filteredData.length > 0 ? (
                filteredData.map((emp) => (
                  <div key={emp.tech_id}
                    className="relative flex flex-col rounded-lg border border-gray-300 bg-white p-4 shadow-sm 
                               focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 
                               hover:border-gray-400">
                    {/* Header: Employee Name & Avatar */}
                    <div className="flex items-center space-x-3 mb-3">
                      <span className="inline-block h-12 w-12 rounded-full overflow-hidden bg-gray-100">
                        <AvatarLocation avatar={emp.avatar} className={'h12'} />
                      </span>
                      <div className="text-lg font-bold text-gray-800">
                        {emp.employee}
                      </div>
                    </div>
                    {emp.last_am ? (
                      <div className="mb-3 border-t border-gray-200 pt-2">
                        <p className="text-sm text-blue-600 font-medium">
                          Last AM
                        </p>
                        <p className="text-sm text-gray-700">
                          Location: {emp.last_am.job_location}
                        </p>
                        <p className="text-sm text-gray-500">
                          Date: <DateFormat date={emp.last_am.date} /> &nbsp;
                          Time:{' '}
                          {new Date(emp.last_am.time * 1000).toLocaleTimeString()}
                        </p>
                      </div>
                    ) : (
                      <div className="mb-3 border-t border-gray-200 pt-2">
                        <p className="text-sm text-red-500 font-medium">
                          No AM record found
                        </p>
                      </div>
                    )}

                    {emp.last_pm ? (
                      <div className="border-t border-gray-200 pt-2">
                        <p className="text-sm text-blue-600 font-medium">
                          Last PM
                        </p>
                        <p className="text-sm text-gray-700">
                          Location: {emp.last_pm.job_location}
                        </p>
                        <p className="text-sm text-gray-500">
                          Date: <DateFormat date={emp.last_pm.date} /> &nbsp;
                          Time:{' '}
                          {new Date(emp.last_pm.time * 1000).toLocaleTimeString()}
                        </p>
                      </div>
                    ) : (
                      <div className="border-t border-gray-200 pt-2">
                        <p className="text-sm text-red-500 font-medium">
                          No PM record found
                        </p>
                      </div>
                    )}
                    </div>
                ))
              ) : (
                <div className="p-4 text-center text-gray-500">No records found.</div>
              )}
            </div>
          )}
        </div>
      </div>

      {/* Optional Right Sidebar */}
      <div className="hidden md:block">
        <div className='pt-4'>
          <div className='border-2'>
            <DayOff />
          </div>
          <br />
          <Alerts />
        </div>
      </div>
    </div>
  );
};

export default CheckInOutPage;
