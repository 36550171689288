// components/AdditionalTech.jsx
import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { TrashIcon } from '@heroicons/react/24/outline';


const AdditionalTech = ({ selectedTech, setSelectedTech, techList, urlID }) => {
  const [selected, setSelected] = useState(null);
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();

  const submitTech = async() => {
    if (!selected) return;
    const isAlreadyAdded = selectedTech.some((tech) => parseInt(tech.tech_id) === parseInt(selected.id)); // Convert both to numbers
    if (isAlreadyAdded) {
      toast.info('This technician has already been added.', { position: 'top-center' });
      // setLoading(false);
      return; // Prevent adding duplicate tech
    }
    const bodyData = { 
        status: 'additionalTech', 
        inspection_id: parseInt(urlID), 
        tech_id: parseInt(selected?.id) 
      };
    try {
      const response = await axios.post(`${apiUrl}/contracts_crud.php`, bodyData,{  
        headers: { 'Content-Type': 'application/json' }
      }); 
      if (response.data[0] === 'success') {
        setSelectedTech(prev => [...prev, { id: response.data[1], tech_id: selected.id, name: selected.name }]);
        setSelected(null);
      } else {
        console.error('Error adding tech');
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error("Session expired. Please login again.", {
        position: "top-center"
        });
        navigate('/login');
        } else if (error.response && error.response.status === 403) {
        toast.error("Oops!! you don't have access to this area. Speak to the CFO.", {
        position: "top-center"
        });
        }
    }
  };

  const removeTech = async(tech, index) => {
    const bodyData = {
      status: 'delTech',
      additionalTech_id: tech.id,
    };
    
    try {
      const response = await axios.post(`${apiUrl}/contracts_crud.php`, bodyData, {
           headers: { 'Content-Type': 'application/json' }
      });
      if (response.data[0] === 'success') {
        const updated = [...selectedTech];
        updated.splice(index, 1);
        setSelectedTech(updated);
      } else {
        console.error('Error removing tech');
      }
    
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error("Session expired. Please login again.", {
        position: "top-center"
        });
        navigate('/login');
        } else if (error.response && error.response.status === 403) {
        toast.error("Oops!! you don't have access to this area. Speak to the CFO.", {
        position: "top-center"
        });
        }
    }
  };

  return (
    <>
    <div className="mb-6">
      <ul className="mb-3">
        {selectedTech.map((x, i) => (
          <li key={i} className="bg-ghostwhite p-2 border-b flex justify-between">
            <span className="uppercase">{x.name}</span>
            <span 
              className="text-red-500 cursor-pointer" 
              onClick={() => removeTech(x, i)}
            >
              <TrashIcon className="h-5 w-5" />
            </span>
          </li>
        ))}
      </ul>

      <select
        className="border p-2 w-1/2 mr-2"
        onChange={e => {
          const val = techList.find(t => t.name === e.target.value);
          setSelected(val || null);
        }}
        value={selected?.name || ''}>
        <option value="">Select</option>
        {techList.map(item => (
          <option key={item.id} value={item.name}>{item.name}</option>
        ))}
      </select>
      <button
        disabled={!selected}
        onClick={submitTech}
        className={`${!selected ? 'bg-gray-300' : 'bg-blue-500'} text-white py-2 px-4 rounded text-md`}>
        Add
      </button>
    </div>
    <br/>
  </>
  );
}

export default AdditionalTech;
