import React from 'react';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const MonthTabs = ({ months, currentMonth, onMonthChange }) => {
  // Ensure months is defined and is an array
  if (!months || !Array.isArray(months) || months.length === 0) {
    return <div>No months available</div>;
  }

  // Find the current month tab
  const currentTab = months.find((month) => month.value === currentMonth);

  return (
    <div>
      {/* For small screens, use a select dropdown */}
      <div className="sm:hidden">
        <label htmlFor="months" className="sr-only">
          Select a month
        </label>
        <select
          id="months"
          name="months"
          className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
          value={currentMonth}
          onChange={(e) => onMonthChange(e.target.value)}
        >
          {months.map((month) => (
            <option key={month.value} value={month.value}>
              {month.name}
            </option>
          ))}
        </select>
      </div>

      {/* For larger screens, display tabs */}
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8" aria-label="Months">
            {months.map((month) => (
              <button
                key={month.value}
                onClick={() => onMonthChange(month.value)}
                className={classNames(
                  month.value === currentMonth
                    ? 'border-indigo-500 text-indigo-600'
                    : 'border-transparent text-gray-500 hover:border-gray-200 hover:text-gray-700',
                  'flex whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium'
                )}
                aria-current={month.value === currentMonth ? 'page' : undefined}
              >
                {month.name}
              </button>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
};

export default MonthTabs;