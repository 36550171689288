import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import ModalWindow from './ModalWindow';  // Your existing code
import LegendCP from './LegendCP';
import SubMenu from './SubMenu';
import LoadingSpinner from './LoadingSpinner';
import SearchBarCP from './SearchBarCP';
import CurrencyFormat from './CurrencyFormat';
import Truncate from './Truncate';
import { ArrowPathIcon } from '@heroicons/react/24/outline';

const InventoryListCp = () => {
  const [inventoryData, setInventoryData] = useState([]);
  const [filteredInventory, setFilteredInventory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  
  // For editing
  const [showEditModal, setShowEditModal] = useState(false);
  const [editData, setEditData] = useState(null);

  // For viewing large notes
  const [notesIdOpen, setNotesIdOpen] = useState(null);

  // For location filtering
  const [locationList, setLocationList] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState('');

  const [fulltotal, setFullTotal] = useState(0);

  const apiUrl = process.env.REACT_APP_API_URL; 
  const navigate = useNavigate();

  useEffect(() => {
    fetchInventory();
    fetchLocations();
  }, []);

  // --- 1) Fetch Inventory
  const fetchInventory = async (locID = '') => {
    try {
      setLoading(true);
      let endpoint = `${apiUrl}/inventory_crud.php?inventory=true`;
      if (locID) {
        endpoint += `&locID=${locID}`;
      }
      const response = await axios.get(endpoint, {
        headers: { 'Content-Type': 'application/json' },
      });
      if (Array.isArray(response.data) && response.data[0] === 'success') {
        setFullTotal(response.data[2] || 0);
        setInventoryData(response.data[1]);
        setFilteredInventory(response.data[1]);
      } else {
        toast.error('Error fetching inventory data', { position: 'top-center' });
      }
    } catch (error) {
      console.error(error);
      if (error.response?.status === 401) {
        navigate('/login');
      } else {
        toast.error('Error fetching data', { position: 'top-center' });
      }
    } finally {
      setLoading(false);
    }
  };

  // --- 2) Fetch all possible locations
  const fetchLocations = async () => {
    try {
      setLoading(true);
      const res = await axios.get(`${apiUrl}/inventory_crud.php?inventoryLocation=true`, {
        headers: { 'Content-Type': 'application/json' },
      });
      if (Array.isArray(res.data) && res.data[0] === 'success') {
        setLocationList(res.data[1]);
      }
    } catch (err) {
      console.error(err);
      if (err.response?.status === 401) {
        navigate('/login');
      }
    } finally {
      setLoading(false);
    }
  };

  // Location change => re-fetch
  const handleLocationChange = (e) => {
    const locID = e.target.value;
    setSelectedLocation(locID);
    fetchInventory(locID);
  };

  // Local search
  const handleSearchChange = (e) => {
    const val = e.target.value;
    setSearchTerm(val);

    if (!val) {
      setFilteredInventory(inventoryData);
    } else {
      const filtered = inventoryData.filter((item) =>
        (item.device && item.device.toLowerCase().includes(val.toLowerCase())) ||
        (item.notes && item.notes.toLowerCase().includes(val.toLowerCase())) ||
        (item.category && item.category.toLowerCase().includes(val.toLowerCase())) ||
        (item.device_id && item.device_id.toString().includes(val))
      );
      setFilteredInventory(filtered);
    }
  };

  // Possibly do server search
  const handleSearchClick = async () => {
    if (!searchTerm) {
      setFilteredInventory(inventoryData);
      return;
    }
    try {
      setLoading(true);
      const bodyData = { status: 'searchInventory', data: { search: searchTerm } };
      const response = await axios.post(`${apiUrl}/inventory_crud.php`, bodyData, {
        headers: { 'Content-Type': 'application/json' },
      });
      if (response.data && Array.isArray(response.data)) {
        setFilteredInventory(response.data[1]);
      } else {
        setFilteredInventory([]);
        toast.error(`Not found: ${searchTerm}`, { position: 'top-center' });
      }
    } catch (error) {
      console.error(error);
      if (error.response?.status === 401) {
        navigate('/login');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleReset = () => {
    setSearchTerm('');
    setFilteredInventory(inventoryData);
  };

  // SubMenu => link for "Edit Item"
  const getLinksForItem = (record) => {
    return [
      { name: 'Edit Item', click: () => openEditModal(record) }
    ];
  };

  // Actually open the new modal to edit reorder_level, notes, location
  const openEditModal = (record) => {
    setEditData({
      inventory_id: record.inventory_id,
      reorder_level: record.reorder_level || 0,
      notes: record.notes || '',
      location_id: record.location_id  // or record.location
    });
    setShowEditModal(true);
  };

  // Called from our custom edit form
  const handleUpdateInventory = async (payload) => {
    if (!payload.location_id) {
      toast.error('Please select a location', { position: 'top-center' });
      return;
    }
    try {
      const response = await axios.post(`${apiUrl}/inventory_crud.php`, {
        status: 'updateInventoryItem',
        data: payload
      }, {
        headers: { 'Content-Type': 'application/json' }
      });
      if (response.data.success) {
        toast.success('Inventory updated!', { position: 'top-center' });
        setShowEditModal(false);
        fetchInventory(selectedLocation);
      } else {
        toast.error('Error updating item', { position: 'top-center' });
      }
    } catch (err) {
      console.error(err);
      toast.error('Error updating item', { position: 'top-center' });
    }
  };

  return (
    <>
      <div className="mx-auto max-w-[1250px] px-4 sm:px-6 lg:px-8 py-8">
        <p className="text-green-800 font-bold text-md p-3">
          {fulltotal > 0 && <span>Total: <CurrencyFormat value={fulltotal} /></span>}
        </p>
        <LegendCP color="fill-yellow-300" label="Inventory @10 (default)" /> 
        <LegendCP color="fill-red-500" label="Below Reorder Lvl" /> 
        <br /><br />

        {/* SELECT location + Search bar */}
        <div className="flex items-center space-x-3 mb-3">
          <select
            className="border border-gray-300 rounded-md px-6 py-2"
            value={selectedLocation}
            onChange={handleLocationChange}
          >
            <option value="">All Locations</option>
            {locationList.map((loc) => (
              <option key={loc.id} value={loc.id}>
                {loc.location} {loc.sub_location}
              </option>
            ))}
          </select>

          <div className="relative flex-grow">
            <input
              type="text"
              name="search"
              value={searchTerm}
              required
              onChange={handleSearchChange}
              className="block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
              placeholder="Enter a search term"
            />
            <button
              onClick={handleSearchClick}
              disabled={!searchTerm}
              className={`absolute right-24 top-0 bottom-0 text-white px-3 py-2 ${
                !searchTerm ? 'bg-gray-500' : 'bg-green-600 hover:bg-green-700'
              }`}
            >
              Search
            </button>
            <button
              onClick={handleReset}
              className="absolute right-0 top-0 bottom-0 bg-orange-600 hover:bg-orange-700 text-white px-3 py-2 rounded-r-md"
            >
              <span className="flex items-center gap-1">
                <ArrowPathIcon className="h-5" />
                Reset
              </span>
            </button>
          </div>
        </div>

        {loading ? (
          <LoadingSpinner>Loading...</LoadingSpinner>
        ) : (
          <div>
            <table className="w-full divide-y divide-gray-300 bg-gray-200 border rounded-lg">
              <thead>
                <tr className="divide-x divide-gray-400">
                  <th scope="col" className="py-3 pl-4 pr-3 text-left text-sm font-semibold text-gray-900">ID</th>
                  <th scope="col" className="px-3 py-3 text-left text-sm font-semibold text-gray-900">
                    Device | Manufacture
                  </th>
                  <th scope="col" className="px-3 py-3 text-left text-sm font-semibold text-gray-900">Description</th>
                  <th scope="col" className="px-3 py-3 text-left text-sm font-semibold text-gray-900">Location</th>
                  <th scope="col" className="px-3 py-3 text-left text-sm font-semibold text-gray-900">Qty</th>
                  <th scope="col" className="px-3 py-3 text-left text-sm font-semibold text-gray-900">Price</th>
                  <th scope="col" className="px-3 py-3 text-left text-sm font-semibold text-gray-900">Ttl</th>
                  <th scope="col" className="px-3 py-3 text-left text-sm font-semibold text-gray-900">Lvl</th>
                  <th scope="col" className="px-3 py-3 text-left text-sm font-semibold text-gray-900">Notes</th>
                </tr>
              </thead>
              <tbody>
                {filteredInventory.map((item, index) => (
                      <tr
                        key={item.inventory_id}
                        className={(() => {
                          // If reorder_level > 0 and current_inventory < reorder_level => red
                          if (
                            parseInt(item.reorder_level) > 0 &&
                            parseInt(item.current_inventory) <= parseInt(item.reorder_level)
                          ) {
                            return 'divide-x bg-red-200';
                          }
                          // If reorder_level is 0 or null, and current_inventory = 10 => yellow
                          else if (
                            (item.reorder_level === 0 || item.reorder_level == null) &&
                            item.current_inventory === 10
                          ) {
                            return 'divide-x bg-yellow-100';
                          }
                          // Else just alternate stripes based on index
                          else {
                            return index % 2 === 0 ? 'divide-x bg-white' : 'divide-x bg-gray-100';
                          }
                        })()}
                      >
                    <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-700">
                      <SubMenu
                        label={item.inventory_id}
                        idItem={item.inventory_id}
                        links={getLinksForItem(item)}
                      />
                    </td>
                    <td className="px-3 py-4 text-sm text-gray-500">
                      <p className="font-semibold">{item.device}</p>
                      <p className="text-gray-400">{item.manufacture}</p>
                    </td>
                    <td className="px-1 py-2 text-sm text-gray-500">
                      {item.description}
                    </td>
                    <td className="px-3 py-4 text-sm text-gray-500">
                      {item.location}
                    </td>
                    <td className="px-3 py-4 text-sm text-gray-500">
                      {item.current_inventory}
                    </td>
                    <td className="px-3 py-4 text-sm text-gray-500">
                      <CurrencyFormat value={item.price} />
                    </td>
                    <td className="px-3 py-4 text-sm text-green-700">
                      <CurrencyFormat value={item.total} />
                    </td>
                    <td className="px-3 py-4 text-sm text-gray-500">
                      {item.reorder_level === 0 ? 10 : item.reorder_level}
                    </td>
                    <td className="px-3 py-4 text-sm text-gray-500 max-w-xs">
                      {item.notes?.length > 50 ? (
                        <>
                          {item.notes.substring(0, 50)}...
                          <button
                            className="ml-2 text-blue-500 underline"
                            onClick={() => setNotesIdOpen(item.inventory_id)}
                          >
                            View More
                          </button>
                          {notesIdOpen === item.inventory_id && (
                            <ModalWindow
                              job={item.notes}
                              closeNotes={() => setNotesIdOpen(null)}
                              activeButton
                            />
                          )}
                        </>
                      ) : (
                        item.notes
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>

      {/*  8) The Edit Modal using your <ModalWindow> component  */}
      {showEditModal && editData && (
        <ModalWindow
          closeNotes={() => setShowEditModal(false)}  // how we close this modal
          activeButton={true} // show close button
          job={
            <EditItemForm
              editData={editData}
              locationList={locationList}
              onClose={() => setShowEditModal(false)}
              onSave={handleUpdateInventory}
            />
          }
        />
      )}
    </>
  );
}

/** 
 * 9) Our internal form for reorder_level, notes, location. 
 * We pass it as "job" to <ModalWindow>. 
 */
function EditItemForm({ editData, locationList, onClose, onSave }) {
  const [reorderLevel, setReorderLevel] = useState(editData.reorder_level || 0);
  const [notes, setNotes] = useState(editData.notes || '');
  const [locId, setLocId] = useState(editData.location_id || '');

  const handleSubmit = (e) => {
    e.preventDefault();
    const payload = {
      inventory_id: editData.inventory_id,
      reorder_level: reorderLevel,
      notes,
      location_id: locId
    };
    onSave(payload); // triggers handleUpdateInventory in parent
  };

  return (
    <div className="p-4">
      <h2 className="text-lg font-bold mb-2">Edit Inventory #{editData.inventory_id}</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        
        <div>
          <label className="block text-sm font-semibold mb-1">Reorder Level</label>
          <input
            type="number"
            className="border rounded p-2 w-full"
            value={reorderLevel}
            onChange={(e) => setReorderLevel(e.target.value)}
          />
        </div>

        <div>
          <label className="block text-sm font-semibold mb-1">Notes</label>
          <textarea
            rows="3"
            className="border rounded p-2 w-full"
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
          />
        </div>

        <div>
          <label className="block text-sm font-semibold mb-1">Location</label>
          <select
            className="border rounded p-2 w-full"
            value={locId}
            onChange={(e) => setLocId(e.target.value)}
          >
            <option value="">Select location</option>
            {locationList.map((loc) => (
              <option key={loc.id} value={loc.id}>
                {loc.location} {loc.sub_location}
              </option>
            ))}
          </select>
        </div>

        <div className="flex justify-end pt-4 space-x-3">
          <button
            type="button"
            onClick={onClose}
            className="bg-gray-300 hover:bg-gray-400 text-black px-4 py-1 rounded"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="bg-blue-600 hover:bg-blue-700 text-white px-4 py-1 rounded"
          >
            Save
          </button>
        </div>
      </form>
    </div>
  );
}

export default InventoryListCp;
