import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import serviceLogo from '../assets/images/service_logo.png';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ServiceSignatureLocator from '../components/ServiceSignatureLocator';

const MaintenanceReport = () => {
  const [inspection, setInspection] = useState(null); // Initialize as null
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [devices, setDevices] = useState([]);
  const [error, setError] = useState(null);
  const appRoot = process.env.REACT_APP_ROOT_URL;

  useEffect(() => {
    setLoading(true);
    const fetchInspection = async () => {
      try {
        const response = await axios.get(`${apiUrl}/contracts_crud.php?getInspectionDetails=${id}`, {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
        });
        console.log(response.data);
        
        if (response.data[0] === 'success') {
          const inspectionData = response.data[1];
          setInspection(inspectionData);
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          toast.error("Session expired. Please login again.", { position: "top-center" });
          navigate('/login');
        } else if (error.response && error.response.status === 403) {
          toast.error("Oops!! you don't have access to this area. Speak to the CFO.", { position: "top-center" });
        }
      } finally {
        setLoading(false);
      }
    };
    fetchInspection();
  }, [apiUrl, navigate, id]);

  const handlePrint = () => {
    window.print();
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!inspection) {
    return <div>No service call data available</div>;
  }

  function getFrequency(frequency) {
    if (frequency === '1') {
      return 'Monthly';
    } else if (frequency === 3) {
      return 'Quarterly';
    } else if (frequency === 6) {
      return 'Semi-Annual';
    } else if (frequency === 12) {
      return 'Annual';
    }
  }

  function getDeviceMessage(deviceObj) {
    const { device, status, cot_company, opt } = deviceObj;

    // 1. device != 'Central Station' && device != 'Trouble in the System?'
    if (status === 'yes' && device !== 'Central Station' && device !== 'Trouble in the System?') {
      return `${device} - Tested and Found to be in proper working order.`;
    } else if (status === 'na') {
      return ''; // you said echo "", so we’ll skip a blank line
    } else if (status === 'no' && device !== 'Central Station' && device !== 'Trouble in the System?') {
      return `${device} - Tested and discrepancies found. Follow-up report to follow.`;
    }

    // 2. Central Station
    if (device === 'Central Station') {
      if (status === 'no') {
        return `${device} - Signals were not received by ${cot_company || '???'} operator's number ${opt || '???'}`;
      } else if (status === 'yes') {
        return `${device} - All signals received by ${cot_company || '???'} operator's number ${opt || '???'}`;
      }
    }

    // 3. Trouble in the System?
    if (device === 'Trouble in the System?') {
      if (status === 'no') {
        return `${device} - Troubles Found, Follow-up report to follow.`;
      } else if (status === 'yes') {
        return `${device} - No Troubles Found upon arrival or departure.`;
      }
    }

    // If none of the conditions matched, return a fallback
    return `${device} - Unknown status.`;
  }

  if (error) {
    return <div className="text-red-500">Error: {error}</div>;
  }

  return (
    <div className="mx-auto bg-white p-6" style={{ width: '8.5in', height: '11in' }}>
      {/* Print Button */}
      <div className="mb-4">
        <button
          onClick={handlePrint}
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 print:hidden"
        >
          Print
        </button>
      </div>

      {/* Include the style block for print-specific CSS */}
      <style>
        {`
          @media print {
            /* Hide the print button */
            .print\\:hidden {
              display: none !important;
            }

            /* Hide the menu bar during print */
            .menu {
              display: none !important;
            }

            /* Ensure the main content stretches across the full width of the page */
            .max-w-5xl {
              max-width: 100% !important;
            }

            /* Prevent page breaks inside tables */
            table {
              width: 100%;
              border-collapse: collapse;
              page-break-inside: avoid; /* Prevents splitting the table */
            }

            th, td {
              border: 1px solid black;
              padding: 8px;
              font-size: 12px; /* Optional: Reduce font size for print */
            }

            /* Ensure all text prints in black */
            * {
              color: black !important;
            }

            /* Adjust body size for print if necessary */
            body {
              font-size: 90%; /* Optional: Reduce the overall font size to fit more content */
            }

            /* Set the table width to fit on one page */
            table {
              table-layout: fixed;
            }

            th, td {
              word-wrap: break-word; /* Allow breaking of long words inside cells */
            }
          }
        `}
      </style>

      <h3 className="text-right text-gray-500 text-2xl mb-4">MAINTENANCE REPORT #{inspection.inspection_id}</h3>

      {/* Customer Info */}
      <div className="bg-white px-4 py-5 mb-4 border border-gray-300 rounded-md">
        <div className="flex justify-between items-start">
          {/* Logo */}
          <div>
            <img src={serviceLogo} alt="logo" className="max-w-96" />
          </div>

          {/* Customer Info */}
          <div className="flex-1 ml-auto">
            <div className="min-w-0">
              <div className="flex items-center">
                <p className="text-sm font-semibold text-gray-900 mr-2">Customer:</p>
                <p className="text-sm text-gray-500">{inspection.customer_name}</p>
              </div>

              <div className="flex items-center mt-2">
                <p className="text-sm font-semibold text-gray-900 mr-2">Address:</p>
                <p className="text-sm text-gray-500">
                  {inspection.job_site_address} 
                  {inspection.job_site_address2 && (' ' + inspection.job_site_address2 + ', ')}
                  {inspection.city} {inspection.state} {inspection.zip_code}
                </p>
              </div>

              <div className="flex items-center mt-2">
                <p className="text-sm font-semibold text-gray-900 mr-2">Requested By:</p>
                <p className="text-sm text-gray-500">
                  {inspection.contact_name} | {inspection.contact_phone}
                </p>
              </div>

              {inspection.customer_notification_email && (
                <div className="flex items-center mt-2">
                  <p className="text-sm font-semibold text-gray-900 mr-2">Sent To:</p>
                  <p className="text-sm text-gray-500">{inspection.customer_notification_email}</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Service Details */}
      <div className="flex flex-wrap gap-4 mb-3">
        <div className="flex-grow flex items-center border border-gray-300 p-2">
          <div className="font-bold mr-2">Date:</div>
          <div>{inspection.schedule_date_to}</div>
        </div>
        <div className="flex-grow flex items-center border border-gray-300 p-2">
          <div>{getFrequency(inspection.frequency)}</div>
        </div>
        <div className="flex-grow flex items-center border border-gray-300 p-2">
          <div className="font-bold mr-2">Complete:</div>
          <div className={inspection.inspection_status === 'Completed' ? 'text-green-600' : 'text-red-600'}>
            {inspection.inspection_status === 'Completed' ? 'YES' : 'NO'}
          </div>
        </div>
        <div className="flex-grow flex items-center border border-gray-300 p-2">
          <div className="font-bold mr-2">Lead Tech:</div>
          <div>{inspection.lead_tech_name}</div>
        </div>
      </div>

      {/* Technician Notes */}
      <div className="border border-black rounded-md p-4 mb-6">
        <div className="font-bold underline mb-1">TECHNICIAN NOTES:</div>
          <div>
            <h3 className="font-semi=bold text-lg mb-2">Device List</h3>
            <ul className="list-disc ml-6">
              {inspection.device_list.map((obj, idx) => {
                const message = getDeviceMessage(obj);
                if (!message) {
                  // skip empty (like status === 'na')
                  return null;
                }
                return (
                  <li key={idx} className="device_list mb-1">
                    {message}
                  </li>
                );
              })}
            </ul>
          </div>
      </div>

      {/* Signature Section */}
      <div className="flex justify-end">
        <div className="w-[55%] border border-black rounded-md p-2">
          <div className="font-bold text-md mb-2">
            The above-mentioned work was satisfactorily performed for:
          </div>
          <div className="flex flex-col items-start">
            {/* Print Name */}
            <div className="w-full mb-2">
              <div className="font-bold text-sm">Print Name:</div>
              <div className="border-b border-black w-full text-sm">
                {inspection.customer_print === 'noemail' ? 'N/A' : inspection.customer_print}
              </div>
            </div>

            {/* Signature Field */}
            <div className="w-full">
              <div className="font-bold text-sm">Signature:</div>
              <div className="border-b border-black w-full h-10">
                {inspection.SERVICE_TICKET === 'noemail' || inspection.SERVICE_TICKET === '' ? (
                  'N/A'
                ) : (
                  <img
                    src={`${appRoot}/maintenance_files/${inspection.customer_signature}`}
                    alt="Customer Signature"
                    className="h-full w-auto max-h-10"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MaintenanceReport;
