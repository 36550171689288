// components/TechNotes.jsx
import axios from 'axios';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const TechNotes = ({ inspectionTech, setInspectionTech, urlID }) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [techNotesButton, setTechNotesButton] = useState('Submit');

  const submitTechNotes = async() => {
    const bodyData = { 
      status: 'inspectionTechNotes',
      id: parseInt(urlID),
      tech_notes: inspectionTech.techNotes
    }
    try {
      const response = await axios.post(`${apiUrl}/contracts_crud.php`, bodyData, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if (response.data[0] === 'success') {
        toast.success('updated notes', { position: 'top-center' });
        setTechNotesButton('Update');
      } else {
        console.error('Error adding tech notes');
      }      
    } catch (error) {
      console.error('Error submitting tech notes');
    }
  };

  return (
    <div className="mb-5">
      <h5 className="font-bold text-gray-700 mb-1">Tech Notes (Management view only)</h5>
      <textarea
        className="border p-2 w-full h-24"
        value={inspectionTech.techNotes || ''}
        onChange={e => setInspectionTech({ ...inspectionTech, techNotes: e.target.value })}
      />
      <button
        disabled={!inspectionTech.techNotes}
        onClick={submitTechNotes}
        className="bg-blue-500 text-white py-1 px-3 rounded text-sm mt-2"
      >
        {techNotesButton}
      </button>
    </div>
  );
}

export default TechNotes;
