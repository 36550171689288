import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import serviceLogo from '../assets/images/service_logo.png';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ServiceSignatureLocator from '../components/ServiceSignatureLocator';

const ServiceTicket = () => {
  const [serviceCall, setServiceCall] = useState(null); // Initialize as null
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [partsData, setPartsData] = useState([]);
  const [hoursData, setHoursData] = useState([]);
  const appRoot = process.env.REACT_APP_ROOT_URL;

  useEffect(() => {
    setLoading(true);
    const fetchServiceCalls = async () => {
      try {
        const response = await axios.get(`${apiUrl}/service_crud.php?getTicket=${id}`, {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
        });
        if (response.data[0] === 'success') {
          const servCall = response.data[1];
          setServiceCall(servCall);
          setPartsData(servCall.add_parts || []);
          setHoursData(servCall.add_hours || []);
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          toast.error("Session expired. Please login again.", { position: "top-center" });
          navigate('/login');
        } else if (error.response && error.response.status === 403) {
          toast.error("Oops!! you don't have access to this area. Speak to the CFO.", { position: "top-center" });
        }
      } finally {
        setLoading(false);
      }
    };
    fetchServiceCalls();
  }, [apiUrl, navigate, id]);

  // Handle the printing
  const handlePrint = () => {
    window.print();
  };

  // Loading state
  if (loading) {
    return <div>Loading...</div>;
  }

  // Fallback if no service call data is available
  if (!serviceCall) {
    return <div>No service call data available</div>;
  }

  return (
    <div className="mx-auto bg-white p-6" style={{ width: '8.5in', height: '11in' }}>
      {/* Print Button */}
      <div className="mb-4">
        <button
          onClick={handlePrint}
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 print:hidden"
        >
          Print
        </button>
      </div>

      {/* Include the style block for print-specific CSS */}
      <style>
        {`
          @media print {
            /* Hide the print button */
            .print\\:hidden {
              display: none !important;
            }

            /* Hide the menu bar during print */
            .menu {
              display: none !important;
            }

            /* Ensure the main content stretches across the full width of the page */
            .max-w-5xl {
              max-width: 100% !important;
            }

            /* Prevent page breaks inside tables */
            table {
              width: 100%;
              border-collapse: collapse;
              page-break-inside: avoid; /* Prevents splitting the table */
            }

            th, td {
              border: 1px solid black;
              padding: 8px;
              font-size: 12px; /* Optional: Reduce font size for print */
            }

            /* Ensure all text prints in black */
            * {
              color: black !important;
            }

            /* Adjust body size for print if necessary */
            body {
              font-size: 90%; /* Optional: Reduce the overall font size to fit more content */
            }

            /* Set the table width to fit on one page */
            table {
              table-layout: fixed;
            }

            th, td {
              word-wrap: break-word; /* Allow breaking of long words inside cells */
            }
          }
        `}
      </style>

      <h3 className="text-right text-gray-500 text-2xl mb-4">SERVICE REQUEST #{serviceCall.id}</h3>

      {/* Customer Info */}
      <div className="bg-white px-4 py-5 mb-4 border border-gray-300 rounded-md">
        <div className="flex justify-between items-start">
          {/* Logo */}
          <div>
            <img src={serviceLogo} alt="logo" className="max-w-96" />
          </div>

          {/* Customer Info */}
          <div className="flex-1 ml-auto">
            <div className="min-w-0">
              <div className="flex items-center">
                <p className="text-sm font-semibold text-gray-900 mr-2">Customer:</p>
                <p className="text-sm text-gray-500">{serviceCall.CUSTOMER}</p>
              </div>

              <div className="flex items-center mt-2">
                <p className="text-sm font-semibold text-gray-900 mr-2">Address:</p>
                <p className="text-sm text-gray-500">{serviceCall.ADDRESS}</p>
              </div>

              <div className="flex items-center mt-2">
                <p className="text-sm font-semibold text-gray-900 mr-2">Requested By:</p>
                <p className="text-sm text-gray-500">{serviceCall.CALLED_BY}</p>
              </div>

              {serviceCall.CUSTOMEREMAIL && (
                <div className="flex items-center mt-2">
                  <p className="text-sm font-semibold text-gray-900 mr-2">Sent To:</p>
                  <p className="text-sm text-gray-500">{serviceCall.CUSTOMEREMAIL}</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Service Details */}
      <div className="flex flex-wrap gap-4 mb-3">
        <div className="flex-grow flex items-center border border-gray-300 p-2">
          <div className="font-bold mr-2">DATE:</div>
          <div>{serviceCall.DATE}</div>
        </div>
        <div className="flex-grow flex items-center border border-gray-300 p-2">
          <div className="font-bold mr-2">PO:</div>
          <div>Not provided</div>
        </div>
        <div className="flex-grow flex items-center border border-gray-300 p-2">
          <div className="font-bold mr-2">COMPLETE:</div>
          <div className={serviceCall.STATUS === 'COMPLETED' ? 'text-green-600' : 'text-red-600'}>
            {serviceCall.STATUS === 'COMPLETED' ? 'YES' : 'NO'}
          </div>
        </div>
        <div className="flex-grow flex items-center border border-gray-300 p-2">
          <div className="font-bold mr-2">TECHNICIAN:</div>
          <div>{serviceCall.TECH}</div>
        </div>
        <div className="flex-grow flex items-center border border-gray-300 p-2">
          <div className="font-bold text-xs">{serviceCall.TYPE || 'INVOICE'}</div>
        </div>
      </div>

      {/* Description of Service */}
      <div className="border border-black rounded-md p-4 mb-6">
        <div className="font-bold underline mb-2">DESCRIPTION OF SERVICE:</div>
        <div className="text-sm">{serviceCall.TROUBLE}</div>
      </div>

      {/* Technician Notes */}
      <div className="border border-black rounded-md p-4 mb-6">
        <div className="font-bold underline mb-2">TECHNICIAN NOTES:</div>
        <div className="text-sm">{serviceCall.TECHNOTES}</div>
      </div>

      <div className="flex gap-4 mb-6">
        {/* Parts Used Table */}
        <table className="table-auto flex-grow border-gray-500 border rounded w-[45%]">
          <thead>
            <tr className="text-sm border border-gray-500">
              <th className="border-r px-4 py-1">Quantity</th>
              <th className="px-4 py-1">Device(s)</th>
            </tr>
          </thead>
          <tbody>
            {partsData.map((part, index) => (
              <tr key={index} className="border border-gray-500">
                <td className="border-r border-gray-500 text-center">{part.quantity}</td>
                <td className="border-gray-500 text-center">{part.part}</td>
              </tr>
            ))}
          </tbody>
        </table>

        {/* Hours Worked Table */}
        <table className="table-auto w-[55%] border border-black p-2">
          <thead>
            <tr className="border-b text-sm">
              <th className="border-r px-4 py-1">Labor Hrs</th>
              <th className="border-r px-4 py-1">Overtime Hrs</th>
              <th className="border-r px-4 py-1">Holiday/Sun. Hrs</th>
              <th className="border-r px-4 py-1">Travel Hrs</th>
              <th className="px-4 py-1">Technician</th>
            </tr>
          </thead>
          <tbody>
            {hoursData.map((hours, index) => (
              <tr key={index}>
                <td className="border-r text-center">{hours.total_hours || 0}</td>
                <td className="border-r text-center">{hours.hour_type === 'Overtime' ? hours.total_hours : 0}</td>
                <td className="border-r text-center">{hours.hour_type === 'Holiday' ? hours.total_hours : 0}</td>
                <td className="border-r text-center">{hours.travel_hours || 0}</td>
                <td className="text-center">{serviceCall.TECH}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Signature Section */}
      <div className="flex justify-end">
        <div className="w-[55%] border border-black rounded-md p-2">
          <div className="font-bold text-md mb-2">
            The above-mentioned work was satisfactorily performed for:
          </div>
          <div className="flex flex-col items-start">
            {/* Print Name */}
            <div className="w-full mb-2">
              <div className="font-bold text-sm">Print Name:</div>
              <div className="border-b border-black w-full text-sm">
                {serviceCall.CUSTOMER_PRINT === 'noemail' ? 'N/A' : serviceCall.CUSTOMER_PRINT}
              </div>
            </div>

            {/* Signature Field */}
            <div className="w-full">
              <div className="font-bold text-sm">Signature:</div>
              <div className="border-b border-black w-full h-10">
                {serviceCall.SERVICE_TICKET === 'noemail' || serviceCall.SERVICE_TICKET === '' ? (
                  'N/A'
                ) : (
                  <img
                    src={`${appRoot}/service_file/${serviceCall.SERVICE_TICKET}`}
                    alt="Customer Signature"
                    className="h-full w-auto max-h-10"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceTicket;
