import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import ModalWindow from './ModalWindow';  // Your existing ModalWindow code
import LegendCP from './LegendCP';
import SubMenu from './SubMenu';
import LoadingSpinner from './LoadingSpinner';
import SearchBarCP from './SearchBarCP';
import Truncate from './Truncate';
import { ArrowPathIcon } from '@heroicons/react/24/outline';
import CurrencyFormat from './CurrencyFormat';
import DateFormat from './DateFormat';

const InventoryTransactionsCp = () => {
  const [transactions, setTransactions] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');

  // For editing transaction
  const [showEditModal, setShowEditModal] = useState(false);
  const [editData, setEditData] = useState(null);

  // For viewing long notes separately
  const [notesIdOpen, setNotesIdOpen] = useState(null);

  // Example: optional location filter or user filter, if desired
  const [locationList, setLocationList] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState('');

  const apiUrl = process.env.REACT_APP_API_URL; 
  const navigate = useNavigate();

  // On mount, fetch the transaction list (and optional locations)
  useEffect(() => {
    fetchTransactions();
    fetchLocations(); // only if you want to filter by location_id
  }, []);

  // 1) Fetch transaction data from your backend
  const fetchTransactions = async (locID = '') => {
    try {
      setLoading(true);
      let endpoint = `${apiUrl}/inventory_crud.php?transactionList=1`;
      if (locID) {
        endpoint += `&locID=${locID}`;
      }
      const res = await axios.get(endpoint, {
        headers: { 'Content-Type': 'application/json' }
      });
      console.log(res.data, 'transactionList response');
      if (Array.isArray(res.data) && res.data[0] === 'success') {
        setTransactions(res.data[1]);
        setFiltered(res.data[1]);
      } else {
        toast.error('Error fetching transactions', { position: 'top-center' });
      }
    } catch (error) {
      console.error(error);
      if (error.response?.status === 401) {
        navigate('/login');
      } else {
        toast.error('Error fetching data', { position: 'top-center' });
      }
    } finally {
      setLoading(false);
    }
  };

  // 2) (Optional) Fetch location list if you want a location filter
  const fetchLocations = async () => {
    try {
      // setLoading(true); // If you don’t want to block, skip re-setting loading
      const res = await axios.get(`${apiUrl}/inventory_crud.php?inventoryLocation=true`, {
        headers: { 'Content-Type': 'application/json' }
      });
      console.log(res.data, 'locations response');
      if (Array.isArray(res.data) && res.data[0] === 'success') {
        setLocationList(res.data[1]);
      }
    } catch (err) {
      console.error(err);
      if (err.response?.status === 401) {
        navigate('/login');
      }
    } finally {
      // setLoading(false);
    }
  };

  // 3) location change => re-fetch filtered transactions
  const handleLocationChange = (e) => {
    const locID = e.target.value;
    setSelectedLocation(locID);
    fetchTransactions(locID);
  };

  // 4) local search
  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);

    if (!value) {
      setFiltered(transactions);
    } else {
      // Example filter by employee_name, job_number, notes, etc.
      const updated = transactions.filter((t) =>
        (t.employee_name && t.employee_name.toLowerCase().includes(value.toLowerCase())) ||
        (t.job_number && t.job_number.toLowerCase().includes(value.toLowerCase())) ||
        (t.notes && t.notes.toLowerCase().includes(value.toLowerCase())) ||
        (t.type && t.type.toLowerCase().includes(value.toLowerCase()))
      );
      setFiltered(updated);
    }
  };

  // Optionally do server search
  const handleSearchClick = async () => {
    if (!searchTerm) {
      setFiltered(transactions);
      return;
    }
    try {
      setLoading(true);
      const bodyData = { status: 'searchTransactions', data: { search: searchTerm } };
      const res = await axios.post(`${apiUrl}/inventory_crud.php`, bodyData, {
        headers: { 'Content-Type': 'application/json' }
      });
      console.log(res.data, 'searchTransactions response');
      if (res.data && Array.isArray(res.data)) {
        setFiltered(res.data[1]);
      } else {
        setFiltered([]);
        toast.error(`Not found: ${searchTerm}`, { position: 'top-center' });
      }
    } catch (err) {
      console.error(err);
      if (err.response?.status === 401) {
        navigate('/login');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleReset = () => {
    setSearchTerm('');
    setFiltered(transactions);
  };

  // 5) Submenu links
  const getLinksForItem = (record) => [
    { name: 'Edit Txn', click: () => openEditModal(record) },
    // { name: 'Delete Txn', click: () => handleDelete(record.id) },
  ];

  // 6) open the new modal to edit one transaction
  const openEditModal = (record) => {
    /**
     * We'll store the relevant fields:
     *  id, employee_name, inventory_id, type, location_id, job_number,
     *  quantity, notes, date
     */
    setEditData({
      id: record.id,
      employee_name: record.employee_name || '',
      inventory_id: record.inventory_id,
      type: record.type || '',
      location_id: record.location_id,
      job_number: record.job_number || '',
      quantity: record.quantity || 0,
      notes: record.notes || '',
      date: record.date || ''
    });
    setShowEditModal(true);
  };

  // 7) Called from child form to update the backend
  const handleUpdateTransaction = async (payload) => {
    // e.g. {id, employee_name, type, location_id, job_number, quantity, notes, date }
    if (!payload.employee_name || !payload.inventory_id) {
      toast.error('Missing required fields', { position: 'top-center' });
      return;
    }
    try {
      const res = await axios.post(`${apiUrl}/inventory_crud.php`, {
        status: 'updateTransaction',
        data: payload
      }, {
        headers: { 'Content-Type': 'application/json' }
      });
      if (res.data.success) {
        toast.success('Transaction updated!', { position: 'top-center' });
        setShowEditModal(false);
        // Re-fetch using existing location filter
        fetchTransactions(selectedLocation);
      } else {
        toast.error('Error updating transaction', { position: 'top-center' });
      }
    } catch (err) {
      console.error(err);
      toast.error('Error updating transaction', { position: 'top-center' });
    }
  };

  return (
    <>
      <div className="mx-auto max-w-[1250px] px-4 sm:px-6 lg:px-8 py-8">

        {/* <LegendCP color="fill-yellow-300" label="Sample Legend #1" /> 
        <LegendCP color="fill-red-500" label="Sample Legend #2" /> */}
        {/* <br/><br/> */}

        {/* SELECT location + Search bar */}
        <div className="flex items-center space-x-3 mb-3">
          <select
            className="border border-gray-300 rounded-md px-6 py-2"
            value={selectedLocation}
            onChange={handleLocationChange}
          >
            <option value="">All Locations</option>
            {locationList.map((loc) => (
              <option key={loc.id} value={loc.id}>
                {loc.location} {loc.sub_location}
              </option>
            ))}
          </select>

          <div className="relative flex-grow">
            <input
              type="text"
              name="search"
              value={searchTerm}
              required
              onChange={handleSearchChange}
              className="block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
              placeholder="Search..."
            />
            <button
              onClick={handleSearchClick}
              disabled={!searchTerm}
              className={`absolute right-24 top-0 bottom-0 text-white px-3 py-2 ${
                !searchTerm ? 'bg-gray-500' : 'bg-green-600 hover:bg-green-700'
              }`}
            >
              Search
            </button>
            <button
              onClick={handleReset}
              className="absolute right-0 top-0 bottom-0 bg-orange-600 hover:bg-orange-700 text-white px-3 py-2 rounded-r-md"
            >
              <span className="flex items-center gap-1">
                <ArrowPathIcon className="h-5" />
                Reset
              </span>
            </button>
          </div>
        </div>

        {loading ? (
          <LoadingSpinner>Loading...</LoadingSpinner>
        ) : (
          <div className="overflow-x-auto">
            <table className="w-full divide-y divide-gray-300 bg-gray-200 border rounded-lg">
              <thead>
                <tr className="divide-x divide-gray-400">
                  <th scope="col" className="py-3 pl-4 pr-3 text-left text-sm font-semibold text-gray-900">ID</th>
                  <th scope="col" className="px-3 py-3 text-left text-sm font-semibold text-gray-900">
                    Employee</th>
                  <th scope="col" className="px-3 py-3 text-left text-sm font-semibold text-gray-900">
                    Tp | Location</th>
                  <th scope="col" className="px-3 py-3 text-left text-sm font-semibold text-gray-900">
                    Job#</th>
                  <th scope="col" className="px-3 py-3 text-left text-sm font-semibold text-gray-900">
                    Qty | Price</th>
                  <th scope="col" className="px-3 py-3 text-left text-sm font-semibold text-gray-900">
                    Date</th>
                  <th scope="col" className="px-3 py-3 text-left text-sm font-semibold text-gray-900">
                    Notes</th>
                </tr>
              </thead>
              <tbody>
                {filtered.map((txn, idx) => (
                  <tr
                    key={txn.id}
                    className={idx % 2 === 0 ? 'divide-x bg-white' : 'divide-x bg-gray-100'}
                  >
                    <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-700">
                      <SubMenu label={txn.id} idItem={txn.id} links={getLinksForItem(txn)} />
                    </td>
                    <td className="px-3 py-4 text-sm text-gray-500">
                      {txn.employee_name}
                    </td>
                    <td className="px-3 py-4 text-sm text-gray-500">
                      <p className={`text-white text-center rounded-md ${txn.type === 'in' ? 'bg-green-700' : 'text-red-700'}`}>
                        {txn.type}
                      </p>
                      <p className='text-gray-500 font-semibold'>{txn.location}</p>
                    </td>
                    <td className="px-3 py-4 text-sm text-gray-500">
                      {txn.job_number}
                    </td>
                    <td className="px-3 py-4 text-sm text-gray-500">
                      <p><CurrencyFormat value={txn.price}/></p>
                      <p>({txn.quantity})</p>
                    </td>
                    <td className="px-3 py-4 text-sm text-gray-500">
                      <DateFormat date={txn.date} />
                    </td>
                    <td className="px-3 py-4 text-sm text-gray-500 max-w-xs">
                      {txn.notes && txn.notes.length > 50 ? (
                        <>
                          {txn.notes.substring(0, 50)}...
                          <button
                            className="ml-2 text-blue-500 underline"
                            onClick={() => setNotesIdOpen(txn.id)}
                          >
                            View More
                          </button>
                          {notesIdOpen === txn.id && (
                            <ModalWindow
                              job={txn.notes}
                              closeNotes={() => setNotesIdOpen(null)}
                              activeButton
                            />
                          )}
                        </>
                      ) : (
                        txn.notes
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>

      {/* The Edit Transaction Modal using your existing ModalWindow */}
      {showEditModal && editData && (
        <ModalWindow
          closeNotes={() => setShowEditModal(false)}
          activeButton={true}
          job={
            <EditTransactionForm
              editData={editData}
              locationList={locationList}
              onClose={() => setShowEditModal(false)}
              onSave={handleUpdateTransaction}
            />
          }
        />
      )}
    </>
  );
};

/** 
 * Renders a form to edit the 9 fields:
 * id, employee_name, inventory_id, type, location_id, job_number, quantity, notes, date
*/
function EditTransactionForm({ editData, locationList, onClose, onSave }) {
  const [employeeName, setEmployeeName] = useState(editData.employee_name || '');
  const [inventoryID, setInventoryID] = useState(editData.inventory_id || 0);
  const [type, setType] = useState(editData.type || '');
  const [locationID, setLocationID] = useState(editData.location_id || '');
  const [jobNumber, setJobNumber] = useState(editData.job_number || '');
  const [quantity, setQuantity] = useState(editData.quantity || 1);
  const [notes, setNotes] = useState(editData.notes || '');
  const [date, setDate] = useState(editData.date || '');

  const handleSubmit = (e) => {
    e.preventDefault();
    // Build payload
    const payload = {
      id: editData.id,
      employee_name: employeeName,
      inventory_id: inventoryID,
      type,
      location_id: locationID,
      job_number: jobNumber,
      quantity,
      notes,
      date
    };
    onSave(payload);
  };

  return (
    <div className="p-4">
      <h2 className="text-lg font-bold mb-2">Edit Transaction #{editData.id}</h2>

      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label className="block text-sm font-semibold mb-1">Employee Name</label>
          <input
            type="text"
            className="border rounded p-2 w-full"
            value={employeeName}
            onChange={(e) => setEmployeeName(e.target.value)}
          />
        </div>

        <div>
          <label className="block text-sm font-semibold mb-1">Inventory ID</label>
          <input
            type="number"
            className="border rounded p-2 w-full"
            value={inventoryID}
            onChange={(e) => setInventoryID(e.target.value)}
          />
        </div>

        <div>
          <label className="block text-sm font-semibold mb-1">Type</label>
          <input
            type="text"
            className="border rounded p-2 w-full"
            value={type}
            onChange={(e) => setType(e.target.value)}
          />
        </div>

        <div>
          <label className="block text-sm font-semibold mb-1">Location</label>
          <select
            className="border rounded p-2 w-full"
            value={locationID}
            onChange={(e) => setLocationID(e.target.value)}
          >
            <option value="">Select location</option>
            {locationList.map((loc) => (
              <option key={loc.id} value={loc.id}>
                {loc.location} {loc.sub_location}
              </option>
            ))}
          </select>
        </div>

        <div>
          <label className="block text-sm font-semibold mb-1">Job Number</label>
          <input
            type="text"
            className="border rounded p-2 w-full"
            value={jobNumber}
            onChange={(e) => setJobNumber(e.target.value)}
          />
        </div>

        <div>
          <label className="block text-sm font-semibold mb-1">Quantity</label>
          <input
            type="number"
            className="border rounded p-2 w-full"
            value={quantity}
            onChange={(e) => setQuantity(e.target.value)}
          />
        </div>

        <div>
          <label className="block text-sm font-semibold mb-1">Notes</label>
          <textarea
            className="border rounded p-2 w-full"
            rows={3}
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
          />
        </div>

        <div>
          <label className="block text-sm font-semibold mb-1">Date</label>
          <input
            type="date"
            className="border rounded p-2 w-full"
            value={date}
            onChange={(e) => setDate(e.target.value)}
          />
        </div>

        <div className="flex justify-end space-x-3 pt-4">
          <button
            type="button"
            onClick={onClose}
            className="bg-gray-300 hover:bg-gray-400 text-black px-4 py-1 rounded"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="bg-blue-600 hover:bg-blue-700 text-white px-4 py-1 rounded"
          >
            Save
          </button>
        </div>
      </form>
    </div>
  );
}

export default InventoryTransactionsCp;
