// ServiceCalView.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ArrowPathIcon } from '@heroicons/react/20/solid';
import { toast } from 'react-toastify';
import { NavLink, useNavigate } from 'react-router-dom';
import HeadingCp from '../components/HeadingCp';
import ServiceCallDetailsModal from '../components/ServiceCallDetailsModal';
import ServiceCallFormModal from '../components/ServiceCallFormModal';
import CalendarHeader from '../components/CalendarHeader';

const ServiceCalView = () => {
  const [serviceCalls, setServiceCalls] = useState([]);
  const [selectedWeek, setSelectedWeek] = useState([]);
  const [viewMode, setViewMode] = useState('week'); // 'week' or 'day'
  const [selectedDay, setSelectedDay] = useState(null); // Used in day view
  const apiUrl = process.env.REACT_APP_API_URL;
  const [loading, setLoading] = useState(false);
  const timeSlots = Array.from({ length: 24 }, (_, hour) => hour);
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal state for adding/editing service call
  const [formData, setFormData] = useState({
    TROUBLE: '',
    CUSTOMER: '',
    ADDRESS: '',
    CALLED_BY: '',
    PHONE: '',
    TECH: '',
    NOTES: '',
    TIME: '',
    end_time: '',
    SCHEDULE_DATE: '',
    customer_id: '',
  });
  const [isEditing, setIsEditing] = useState(false); // Flag to check if editing
  const [isDetailModalOpen, setIsDetailModalOpen] = useState(false); // Modal state for viewing service call details
  const [selectedServiceCall, setSelectedServiceCall] = useState(null); // Selected service call for detail modal
  const [selectedCompany, setSelectedCompany] = useState(null);
  const navigate = useNavigate();
  const [employees, setEmployees] = useState([]);
  const [selectedTechs, setSelectedTechs] = useState([]); // New state for multiple techs

  useEffect(() => {
    const fetchServiceCalls = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${apiUrl}/service_crud.php?getList=1`, {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
        });
        const calls = Array.isArray(response.data) ? response.data : [];
        if (calls.length === 0) {
          toast.error('No service calls found!', { position: 'top-center' });
        } else {
          // Ensure 'end_time' is included in each service call
          setServiceCalls(
            calls.map((call) => ({
              ...call,
              end_time: call.end_time || '', // Default to empty string if not present
            }))
          );
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          toast.error('Session expired. Please login again.', {
            position: 'top-center',
          });
          navigate('/login');
        } else if (error.response && error.response.status === 403) {
          toast.error("Oops!! you don't have access to this area. Speak to the CFO.", {
            position: 'top-center',
          });
        }
      } finally {
        setLoading(false);
      }
    };

    const currentWeekStart = getWeekStart();
    setSelectedWeek(getWeekDates(currentWeekStart)); // Set the initial week (current week)
    fetchServiceCalls();
  }, [apiUrl, navigate]);

  useEffect(() => {
    setLoading(true);
    const fetchEmployees = async () => {
      try {
        const response = await axios.get(`${apiUrl}/service_crud.php?employee_list=true`, {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
        });
        const employeesData = Array.isArray(response.data) ? response.data : [];
        if (employeesData.length === 0) {
          toast.error('No employees found!', { position: 'top-center' });
        }
        setEmployees(employeesData);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          toast.error('Session expired. Please login again.', { position: 'top-center' });
          navigate('/login');
        } else if (error.response && error.response.status === 403) {
          toast.error("Oops!! you don't have access to this area. Speak to the CFO.", {
            position: 'top-center',
          });
        }
      } finally {
        setLoading(false);
      }
    };

    fetchEmployees();
  }, [apiUrl, navigate]);

  // Function to generate random colors for events
  const getRandomColor = () => {
    const colors = [
      'bg-red-200',
      'bg-blue-200',
      'bg-green-200',
      'bg-yellow-200',
      'bg-purple-200',
      'bg-pink-200',
      'bg-indigo-200',
    ];
    return colors[Math.floor(Math.random() * colors.length)];
  };
  const techColors = {};
  const getTechColor = (techName) => {
    if (!techColors[techName]) {
      techColors[techName] = getRandomColor();
    }
    return techColors[techName];
  };

  const handleReset = () => {
    window.location.reload();
  };

  // Helper function to get the start of the week (Monday)
  const getWeekStart = (date = new Date()) => {
    const dayOfWeek = (date.getDay() + 6) % 7; // Adjust to get Monday as the first day
    const diff = date.getDate() - dayOfWeek;
    return new Date(date.setDate(diff));
  };

  // Get array of dates for the selected week
  const getWeekDates = (startOfWeek) => {
    const dates = [];
    for (let i = 0; i < 7; i++) {
      const currentDay = new Date(startOfWeek);
      currentDay.setDate(startOfWeek.getDate() + i);
      dates.push(new Date(currentDay));
    }
    return dates;
  };

  // Handle moving to the previous week
  const handlePreviousWeek = () => {
    const previousWeekStart = new Date(selectedWeek[0]); // Start of the current week
    previousWeekStart.setDate(previousWeekStart.getDate() - 7); // Move back 7 days
    setSelectedWeek(getWeekDates(previousWeekStart));
  };

  // Handle moving to the next week
  const handleNextWeek = () => {
    const nextWeekStart = new Date(selectedWeek[0]); // Start of the current week
    nextWeekStart.setDate(nextWeekStart.getDate() + 7); // Move forward 7 days
    setSelectedWeek(getWeekDates(nextWeekStart));
  };

  // Handle moving to the previous day
  const handlePreviousDay = () => {
    const previousDay = new Date(selectedDay);
    previousDay.setDate(previousDay.getDate() - 1);
    setSelectedDay(previousDay);
  };

  // Handle moving to the next day
  const handleNextDay = () => {
    const nextDay = new Date(selectedDay);
    nextDay.setDate(nextDay.getDate() + 1);
    setSelectedDay(nextDay);
  };

  // Handle view mode change
  const handleViewChange = (e) => {
    const view = e.target.value;
    setViewMode(view);
    if (view === 'day') {
      setSelectedDay(new Date()); // Set to today's date
    }
  };

  // Handle opening the modal for adding/editing service call
  const openModal = (isEdit = false) => {
    setIsEditing(isEdit);
    setIsModalOpen(true);
  };

  // Handle closing the modal for adding/editing service call
  const closeModal = () => {
    setIsModalOpen(false);
    setIsEditing(false);
    setFormData({
      TROUBLE: '',
      CUSTOMER: '',
      ADDRESS: '',
      CALLED_BY: '',
      PHONE: '',
      TECH: '',
      NOTES: '',
      TIME: '',
      end_time: '',
      SCHEDULE_DATE: '',
      customer_id: '',
    });
    setSelectedCompany(null);
  };

  const handleCompanySelect = (newJobSite) => {
    if (newJobSite && newJobSite.full_address) {
      setSelectedCompany(newJobSite);
      setFormData((prev) => ({
        ...prev,
        CUSTOMER: newJobSite.customer_name,
        ADDRESS: newJobSite.full_address,
        customer_id: newJobSite.customer_id,
      }));
    } else {
      // console.error('Invalid company selected:', newJobSite);
    }
  };

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Handle form submission
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    // Basic validation
    if (!formData.CUSTOMER || !formData.ADDRESS || !formData.TROUBLE) {
      toast.error('Please fill in the required fields.');
      return;
    }

    try {
      if (isEditing) {
        const NewBodyData = {
          data: formData,
          id: formData.id,
          status: 'addServiceCall',
        };
        // Update existing service call
        const response = await axios.post(`${apiUrl}/service_crud.php`, NewBodyData, {
          headers: { 'Content-Type': 'application/json' },
        });
        if (response.data[0] === 'success') {
          toast.success('Service call updated successfully!');
          closeModal();
          // Refresh the service calls list
          const callsResponse = await axios.get(`${apiUrl}/service_crud.php?getList=1`, {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
          });
          const calls = Array.isArray(callsResponse.data) ? callsResponse.data : [];
          setServiceCalls(
            calls.map((call) => ({
              ...call,
              end_time: call.end_time || '',
            }))
          );
        } else {
          toast.error(response.data.error || 'Failed to update service call.');
        }
      } else {
        const NewBodyData = {
          data: formData,
          id: 'new',
          status: 'addServiceCall',
        };
        // Add new service call
        const response = await axios.post(`${apiUrl}/service_crud.php`, NewBodyData, {
          headers: { 'Content-Type': 'application/json' },
        });
        if (response.data[0] === 'success') {
          toast.success('Service call added successfully!');
          closeModal();
          // Refresh the service calls list
          const callsResponse = await axios.get(`${apiUrl}/service_crud.php?getList=1`, {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
          });
          const calls = Array.isArray(callsResponse.data) ? callsResponse.data : [];
          setServiceCalls(
            calls.map((call) => ({
              ...call,
              end_time: call.end_time || '',
            }))
          );
        } else {
          toast.error(response.data.error || 'Failed to add service call.');
        }
      }
    } catch (error) {
      toast.error('Oops minor issue with the data.', {
        position: 'top-center',
      });
      if (error.response && error.response.status === 401) {
        toast.error('Session expired. Please login again.', {
          position: 'top-center',
        });
        navigate('/login');
      } else if (error.response && error.response.status === 403) {
        toast.error("Oops!! you don't have access to this area. Speak to the CFO.", {
          position: 'top-center',
        });
      }
    }
  };

  // Handle clicking on a time slot
  const handleTimeSlotClick = (date, hour) => {
    // Build the datetime object
    const selectedDateTime = new Date(date);
    selectedDateTime.setHours(hour);
    selectedDateTime.setMinutes(0);
    selectedDateTime.setSeconds(0);
    selectedDateTime.setMilliseconds(0);

    // Calculate default end time (e.g., 1 hour later)
    const defaultEndTime = new Date(selectedDateTime.getTime() + 1 * 60 * 60 * 1000);

    // Open the modal
    openModal(false); // Not editing

    // Set the form data
    setFormData((prevData) => ({
      ...prevData,
      SCHEDULE_DATE: date.toLocaleDateString('en-CA'), // format as YYYY-MM-DD
      TIME: selectedDateTime.toTimeString().split(' ')[0].slice(0, 5), // format as HH:MM
      end_time: defaultEndTime.toTimeString().split(' ')[0].slice(0, 5),
    }));
  };

  // Handle clicking on a service call event
  const handleEventClick = (serviceCall) => {
    setSelectedServiceCall(serviceCall);
    setIsDetailModalOpen(true);
  };

  // Close the detail modal
  const closeDetailModal = () => {
    setIsDetailModalOpen(false);
    setSelectedServiceCall(null);
  };

  // Handle editing a service call
  const handleEditServiceCall = () => {
    // Populate form data with selected service call
    setFormData(selectedServiceCall);
    closeDetailModal(); // Close the detail modal
    openModal(true); // Open the modal in edit mode
  };

  const handleFilterTech = async (event) => {
    const options = event.target.options;
    const selectedValues = [];
    for (let i = 0; i < options.length; i++) {
      if (options[i].selected) {
        selectedValues.push(options[i].value);
      }
    }
    setSelectedTechs(selectedValues);

    setLoading(true);
    try {
      let calls = [];
      if (selectedValues.length === 0) {
        // No techs selected, fetch all service calls
        const response = await axios.get(`${apiUrl}/service_crud.php?getList=1`, {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
        });
        calls = Array.isArray(response.data) ? response.data : [];
      } else {
        const FilterBodyData = {
          techNames: selectedValues,
          status: 'filter_tech',
        };
        // Filter service calls by technicians
        const response = await axios.post(`${apiUrl}/service_crud.php`, FilterBodyData, {
          headers: { 'Content-Type': 'application/json' },
        });
        calls = Array.isArray(response.data) ? response.data : [];
      }

      if (calls.length === 0) {
        toast.error('No service calls found!', { position: 'top-center' });
      } else {
        setServiceCalls(
          calls.map((call) => ({
            ...call,
            end_time: call.end_time || '',
          }))
        );
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error('Session expired. Please login again.', {
          position: 'top-center',
        });
        navigate('/login');
      } else if (error.response && error.response.status === 403) {
        toast.error("Oops!! you don't have access to this area. Speak to the CFO.", {
          position: 'top-center',
        });
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="mx-auto max-w-6xl sm:px-6 lg:px-8 py-10">
      <HeadingCp label={'Service Calendar View'} />
      <div className="flex justify-between items-start mb-3">
        <div className="flex items-center space-x-4">
          {/* Filter Tech */}
          <div className="sm:col-span-2 -mt-2">
            <select
              id="employeeSelection"
              name="employeeFilter"
              multiple
              onChange={handleFilterTech}
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm
                            focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm h-32"
            >
              {employees.map((employee) => (
                <option key={employee.id} value={`${employee.first_name} ${employee.last_name}`}>
                  {employee.first_name} {employee.last_name}
                </option>
              ))}
            </select>
          </div>

          {/* Calendar Header */}
          <CalendarHeader
            viewMode={viewMode}
            selectedWeek={selectedWeek}
            selectedDay={selectedDay}
            handlePreviousWeek={handlePreviousWeek}
            handleNextWeek={handleNextWeek}
            handlePreviousDay={handlePreviousDay}
            handleNextDay={handleNextDay}
          />

          {/* View Mode Select */}
          <select
            id="view-mode"
            className="rounded-md border border-gray-300 py-2 px-4"
            value={viewMode}
            onChange={handleViewChange}
          >
            <option value="week">Week View</option>
            <option value="day">Day View</option>
          </select>
        </div>
      </div>

      {/* View Mode Selector */}
      <div className="flex justify-between items-center mb-8">
        {/* Group select and button inline */}
        <div className="flex items-center space-x-4">
          {/* Dashboard View Button */}
          <NavLink to={`/service_dashboard`}>
            <button
              type="button"
              className="px-4 py-2 border border-transparent text-sm font-medium rounded-md 
                        shadow-sm text-white bg-green-400 hover:bg-green-700 focus:outline-none 
                        focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
            >
              Dashboard
            </button>
          </NavLink>

          {/* New Service Call Button */}
          <button
            type="button"
            onClick={() => openModal(false)}
            className="px-4 py-2 border border-transparent text-sm font-medium rounded-md 
                      shadow-sm text-white bg-orange-400 hover:bg-orange-700 focus:outline-none 
                      focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
          >
            New Service Call +
          </button>

          <button
            onClick={handleReset}
            className="bg-orange-600 hover:bg-orange-700 text-white px-3 py-2 rounded-md"
          >
            <span className="flex items-center gap-1">
              <ArrowPathIcon className="h-5" /> Reset
            </span>
          </button>
        </div>
      </div>

      {/* Calendar Grid */}
      <div className="overflow-auto">
        <div className={`grid ${viewMode === 'week' ? 'grid-cols-8' : 'grid-cols-[auto_1fr]'}`}>
          {/* Time Column */}
          <div className="col-span-1">
            {/* Empty Header Cell */}
            <div className="border border-gray-200 h-12 w-24 bg-gray-100 sticky top-0 z-20">
              {/* Optional Label */}
            </div>
            {/* Time Slots */}
            {timeSlots.map((hour) => (
              <div
                key={hour}
                className="border border-gray-200 h-16 text-right pr-2 pt-2 w-24 bg-white sticky left-0 z-10"
              >
                {`${hour % 12 || 12} ${hour >= 12 ? 'PM' : 'AM'}`}
              </div>
            ))}
          </div>

          {/* Days Columns */}
          {viewMode === 'week'
            ? selectedWeek.map((date, dayIndex) => (
                <div key={dayIndex} className="col-span-1 border-l relative">
                  {/* 'Day' Header */}
                  <div className="border border-gray-200 h-12 text-center  bg-gray-100 sticky top-0 z-10">
                    <span>{date.toLocaleDateString('en-US', { weekday: 'short' })}</span>
                    <br />
                    <span>{date.getDate()}</span>
                  </div>

                  {/* Time Slots */}
                  <div className="relative">
                    {timeSlots.map((hour) => (
                      <div
                        key={hour}
                        className="border border-gray-200 h-16 cursor-pointer hover:bg-gray-200"
                        onClick={() => handleTimeSlotClick(date, hour)}></div>
                    ))}

                    {/* Events */}
                    {serviceCalls
                      .filter((call) => {
                        const callDate = new Date(`${call.SCHEDULE_DATE}T${call.TIME}`);
                        return callDate.toDateString() === date.toDateString();
                      })
                      .map((call, callIndex, array) => {
                        const techColorClass = getTechColor(call.TECH);
                        const startTime = new Date(`${call.SCHEDULE_DATE}T${call.TIME}`);
                        const endTime = call.end_time
                          ? new Date(`${call.SCHEDULE_DATE}T${call.end_time}`)
                          : new Date(startTime.getTime() + 1 * 60 * 60 * 1000); // Default to 1-hour duration if end_time is not set

                        const startHour = startTime.getHours();
                        const startMinutes = startTime.getMinutes();

                        const topOffset = startHour * 64 + (startMinutes / 60) * 64; // Each hour slot is 64px high
                        const durationInMinutes = (endTime - startTime) / (1000 * 60);
                        const eventHeight = (durationInMinutes / 60) * 64; // Height proportional to duration

                        // Calculate overlapping events
                        const overlappingEvents = array.filter((otherCall) => {
                          const otherStartTime = new Date(
                            `${otherCall.SCHEDULE_DATE}T${otherCall.TIME}`
                          );
                          const otherEndTime = otherCall.end_time
                            ? new Date(`${otherCall.SCHEDULE_DATE}T${otherCall.end_time}`)
                            : new Date(otherStartTime.getTime() + 1 * 60 * 60 * 1000);

                          return (
                            otherStartTime.getTime() < endTime.getTime() &&
                            otherEndTime.getTime() > startTime.getTime()
                          );
                        });

                        const overlappingIndex = overlappingEvents.findIndex((e) => e === call);
                        const eventWidth = 100 / overlappingEvents.length;
                        const leftOffset = overlappingIndex * eventWidth;

                        // Filter by selected techs
                        if (
                          selectedTechs.length === 0 ||
                          selectedTechs.includes(call.TECH)
                        ) {
                          return (
                            <div
                              key={callIndex}
                              className={`absolute ${techColorClass} p-2 rounded-lg text-xs leading-5 hover:bg-opacity-75 cursor-pointer border-gray-400 border`}
                              style={{
                                top: `${topOffset}px`,
                                height: `${eventHeight}px`,
                                width: `${eventWidth}%`,
                                left: `${leftOffset}%`,
                              }}
                              onClick={(e) => {
                                e.stopPropagation(); // Prevent triggering the time slot click
                                handleEventClick(call);
                              }}
                            >
                              {call.TECH ? (
                                <p className="font-bold text-black">{call.TECH}</p>
                              ) : (
                                <p className="font-bold text-red-700">Not assigned</p>
                              )}

                              <p className="text-gray-500">{call.ADDRESS}</p>
                            </div>
                          );
                        } else {
                          return null; // Do not render calls not matching selected techs
                        }
                      })}
                  </div>
                </div>
              ))
            : // Day View
              (() => {
                const date = selectedDay;
                return (
                  <div className="col-span-1 border-l relative">
                    {/* Day Header */}
                    <div className="border border-gray-200 h-12 text-center bg-gray-100 sticky top-0 z-10">
                      <span>
                        {date?.toLocaleDateString('en-US', {
                          weekday: 'long',
                          month: 'short',
                          day: 'numeric',
                        })}
                      </span>
                    </div>

                    {/* Time Slots */}
                    <div className="relative">
                      {timeSlots.map((hour) => (
                        <div
                          key={hour}
                          className="border border-gray-200 h-16 cursor-pointer hover:bg-gray-100"
                          onClick={() => handleTimeSlotClick(date, hour)}
                        ></div>
                      ))}

                      {/* Events */}
                      {serviceCalls
                        .filter((call) => {
                          const callDate = new Date(`${call.SCHEDULE_DATE}T${call.TIME}`);
                          return callDate.toDateString() === date?.toDateString();
                        })
                        .map((call, callIndex, array) => {
                          const techColorClass = getTechColor(call.TECH);
                          const startTime = new Date(`${call.SCHEDULE_DATE}T${call.TIME}`);
                          const endTime = call.end_time
                            ? new Date(`${call.SCHEDULE_DATE}T${call.end_time}`)
                            : new Date(startTime.getTime() + 1 * 60 * 60 * 1000); // Default to 1-hour duration if end_time is not set

                          const startHour = startTime.getHours();
                          const startMinutes = startTime.getMinutes();
                          const topOffset = startHour * 64 + (startMinutes / 60) * 64;
                          const durationInMinutes = (endTime - startTime) / (1000 * 60);
                          const eventHeight = (durationInMinutes / 60) * 64;

                          // Calculate overlapping events
                          const overlappingEvents = array.filter((otherCall) => {
                            const otherStartTime = new Date(
                              `${otherCall.SCHEDULE_DATE}T${otherCall.TIME}`
                            );
                            const otherEndTime = otherCall.end_time
                              ? new Date(`${otherCall.SCHEDULE_DATE}T${otherCall.end_time}`)
                              : new Date(otherStartTime.getTime() + 1 * 60 * 60 * 1000);

                            return (
                              otherStartTime.getTime() < endTime.getTime() &&
                              otherEndTime.getTime() > startTime.getTime()
                            );
                          });

                          const overlappingIndex = overlappingEvents.findIndex((e) => e === call);
                          const eventWidth = 100 / overlappingEvents.length;
                          const leftOffset = overlappingIndex * eventWidth;

                          // Filter by selected techs
                          if (
                            selectedTechs.length === 0 ||
                            selectedTechs.includes(call.TECH)
                          ) {
                            return (
                              <div
                                key={callIndex}
                                className={`absolute ${techColorClass} p-2 rounded-lg text-xs leading-5 hover:bg-opacity-75 cursor-pointer border-gray-400 border`}
                                style={{
                                  top: `${topOffset}px`,
                                  height: `${eventHeight}px`,
                                  width: `${eventWidth}%`,
                                  left: `${leftOffset}%`,
                                }}
                                onClick={(e) => {
                                  e.stopPropagation(); // Prevent triggering the time slot click
                                  handleEventClick(call);
                                }}
                              >
                                <p className="font-bold text-black">{call.TECH}</p>
                                <p className="font-semibold">{call.CUSTOMER}</p>
                                <p className="text-gray-700">
                                  <time>
                                    {startTime.toLocaleTimeString([], {
                                      hour: '2-digit',
                                      minute: '2-digit',
                                    })}
                                  </time>
                                  {' - '}
                                  {endTime.toLocaleTimeString([], {
                                    hour: '2-digit',
                                    minute: '2-digit',
                                  })}
                                </p>
                                <p className="text-orange-800">{call.TROUBLE}</p>
                                <p className="">{call.NOTES}</p>
                              </div>
                            );
                          } else {
                            return null; // Do not render calls not matching selected techs
                          }
                        })}
                    </div>
                  </div>
                );
              })()}
        </div>
      </div>

      {/* Modal for Adding/Editing Service Call */}
      <ServiceCallFormModal
        isOpen={isModalOpen}
        onClose={closeModal}
        isEditing={isEditing}
        formData={formData}
        onInputChange={handleInputChange}
        onSubmit={handleFormSubmit}
        selectedCompany={selectedCompany} // from selection company component
        handleCompanySelect={handleCompanySelect} // from selection company component
      />

      {/* Modal for Viewing Service Call Details */}
      <ServiceCallDetailsModal
        isOpen={isDetailModalOpen}
        onClose={closeDetailModal}
        serviceCall={selectedServiceCall}
        onEdit={handleEditServiceCall}
      />
    </div>
  );
};

export default ServiceCalView;
