// InspectionDetailsModal.js

import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';

const InspectionDetailsModal = ({ isOpen, onClose, inspection, onEdit }) => {

  const formatDate = (dateString) => {
    if (!dateString) return '';
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, options);
  };

  const formatTimeTo12Hour = (time24) => {
    if (!time24) return '';
    const [hourStr, minuteStr] = time24.split(':');
    let hour = parseInt(hourStr, 10);
    const minute = minuteStr;
    const ampm = hour >= 12 ? 'PM' : 'AM';
    hour = hour % 12 || 12; // Adjust 0 and 12 to 12
    return `${hour}:${minute} ${ampm}`;
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95">
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-screen items-center justify-center p-4">
              <Dialog.Panel className="mx-auto max-w-xl w-full bg-white rounded-lg p-6 shadow-lg">
                <Dialog.Title className="text-lg font-medium text-gray-900">
                  Inspection Details
                </Dialog.Title>
                {inspection && (
                  <div className="mt-4 space-y-4">

                    <div>
                      <p className="text-sm font-medium text-gray-700">Job Site Address:</p>
                      <p className="text-gray-900">{inspection.job_site_address}</p>
                    </div>
                    <div>
                      <p className="text-sm font-medium text-gray-700">Customer:</p>
                      <p className="text-gray-900">{inspection.customer_name}</p>
                    </div>
                    <div>
                      <p className="text-sm font-medium text-gray-700">Lead Technician:</p>
                      <p className="text-gray-900">
                        {inspection.lead_tech_first_name} {inspection.lead_tech_last_name}
                      </p>
                    </div>
                    <div>
                      <p className="text-sm font-medium text-gray-700">Scheduled Date:</p>
                      <p className="text-gray-900">
                        {/* {formatDate(inspection.scheduled_due_date)} */}
                        {inspection.scheduled_due_date}
                      </p>
                    </div>
                    <div>
                      <p className="text-sm font-medium text-gray-700">Scheduled Time:</p>
                      <p className="text-gray-900">
                        {inspection.schedule_time_in
                          ? formatTimeTo12Hour(inspection.schedule_time_in)
                          : 'No Time'}{' '}
                        -{' '}
                        {inspection.schedule_time_out
                          ? formatTimeTo12Hour(inspection.schedule_time_out)
                          : 'No Time'}
                      </p>
                    </div>
                    <div>
                      <p className="text-sm font-medium text-gray-700">Status:</p>
                      <p className="text-gray-900">{inspection.status}</p>
                    </div>
                    <div>
                      <p className="text-sm font-medium text-gray-700">Notes:</p>
                      <p className="text-gray-900">{inspection.notes}</p>
                    </div>
                    {/* Add other fields as needed */}
                  </div>
                )}
                <div className="mt-6 flex justify-end space-x-2">
                  <button
                    type="button"
                    onClick={onClose}
                    className="inline-flex justify-center rounded-md border border-transparent bg-gray-300 px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-400"
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    onClick={onEdit}
                    className="inline-flex justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700"
                  >
                    Edit
                  </button>
                </div>
              </Dialog.Panel>
            </div>
          </div>

        </Transition.Child>
      </Dialog>
    </Transition>
  );
};

export default InspectionDetailsModal;