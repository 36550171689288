import { ArrowDownCircleIcon, ArrowUpCircleIcon } from '@heroicons/react/24/outline';
import React, { useRef, useEffect, useState } from 'react';

const Accordion = ({ title, content, isOpen, toggle }) => {
  const contentRef = useRef(null);
  const [height, setHeight] = useState('0px');

  useEffect(() => {
    setHeight(isOpen ? `${contentRef.current.scrollHeight}px` : '0px');
  }, [isOpen]);

  return (
    <div className="border-b border-gray-300">
      <button
        onClick={toggle}
        className={`w-full text-left flex items-center justify-between p-4 hover:bg-gray-200 focus:outline-none ${isOpen? 'bg-gray-200' : 'bg-white'}`}
      >
        <span className="text-lg font-medium">{title}</span>
        <span>
          {isOpen ? (
            <ArrowUpCircleIcon className='h-6' />
          ) : (
            <ArrowDownCircleIcon className='h-6 text-blue-700' />
          )}
        </span>
      </button>
      <div
        ref={contentRef}
        style={{ maxHeight: height }}
        className="overflow-hidden transition-max-height duration-300 ease-in-out">
        <div className="p-4 bg-gray-50 border">
          <div className="text-gray-700">{content}</div>
        </div>
      </div>
    </div>
  );
};

export default Accordion;
