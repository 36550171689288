import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const CustomerEmail = ({
  inspection_report,
  setInspectionReport,
  activateSignature,
  setActivateSignature,  // <--- NEW: The setter
  urlID,
  signature
}) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const [customerEmailButton, setCustomerEmailButton] = useState(
    inspection_report.customerEmail ? 'Update' : 'Add'
  );

  const submitCustomerEmail = async () => {
    const bodyData = { 
      status: 'addEmail',
      customer_email: inspection_report.customerEmail,
      customer_print: inspection_report.customerPrint,
      inspection_id: parseInt(urlID)
    };
    console.log(bodyData, 'bodyData from CustomerEmail');

    try {
      const response = await axios.post(`${apiUrl}/contracts_crud.php`, bodyData, {
        headers: { 'Content-Type': 'application/json' }
      });
      console.log(response.data, 'response from CustomerEmail');

      // Suppose your backend returns an array where index 0 is 'success'
      // OR if your backend returns { "success": true } as an object.
      // Adjust checks accordingly:
      if (Array.isArray(response.data) && response.data[0] === 'success') {
        console.log('Email Added/Updated');
        setCustomerEmailButton('Update');
        // Activate the signature now that we have a successful response
        setActivateSignature(true);
      } else if (response.data.success) {
        // If your backend returns an object with "success" property
        console.log('Email Added/Updated');
        setCustomerEmailButton('Update');
        // Activate the signature now that we have a successful response
        setActivateSignature(true);
      } else {
        console.error('Error adding email');
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error("Session expired. Please login again.", {
          position: "top-center"
        });
        navigate('/login');
      } else if (error.response && error.response.status === 403) {
        toast.error("Oops!! you don't have access to this area. Speak to the CFO.", {
          position: "top-center"
        });
      }
    }
  };

  return (
    <div className="mb-5">
      <h5 className="font-bold text-gray-700 mb-2">
        Customer's Email <span className="text-red-500 text-sm">(required)</span>
      </h5>
      <input
        type="email"
        className="border p-2 w-full mb-2"
        placeholder="email@email.com"
        value={inspection_report.customerEmail || ''}
        onChange={e =>
          setInspectionReport({ ...inspection_report, customerEmail: e.target.value })
        }
      />
      <input
        type="text"
        className="border p-2 w-full mb-2"
        placeholder="Print Name"
        value={inspection_report.customerPrint || ''}
        onChange={e =>
          setInspectionReport({ ...inspection_report, customerPrint: e.target.value })
        }
      />

      <button
        disabled={!inspection_report.customerEmail || !inspection_report.customerPrint}
        onClick={submitCustomerEmail}
        className="bg-blue-500 text-white py-1 px-3 rounded text-sm mr-2"
      >
        {customerEmailButton}
      </button>

      {activateSignature && (
        <button className="bg-blue-500 text-white py-1 px-3 rounded text-sm" onClick={signature}>
          Add Signature
        </button>
      )}
    </div>
  );
};

export default CustomerEmail;
